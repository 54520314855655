import React from 'react';

// Customizable Area Start
import {
  Box,
  Button,
  IconButton,
  Typography,
  TextField,
  Modal,
  Snackbar,
  Alert,
  Avatar,
} from '@mui/material';
import {
  styled,
} from '@mui/material/styles';
import Layout from '../../../components/src/Layout.web';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { blankCircle, greenCheckCircle } from "../../../blocks/email-account-login/src/assets";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  
  "*": { fontFamily: "Inter, sans-serif" },
  "& .avatar": {
    background: "linear-gradient(180deg, #FFFFFF 0%, #C1C1C1 100%)",
    width: "148px",
    height: "148px",
    color: "#00184C",
    marginTop: "-74px",
    fontSize: "48px"
}, 
"& .active": {
    padding: "7px 16px 7px 16px",
    borderRadius: "40px",
    background: "#D1FAE5",
    color: "#059669",
    fontWeight: 700,
    fontSize: "12px",
    marginTop: "-10px",
    zIndex: 1
},
}));
// Customizable Area End

import UserProfileBasicController, {
  Props,
} from './UserProfileBasicController';
import { ic_back } from '../../../blocks/accountgroups/src/assets';
import { icPen, profileBg } from './assets';

export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleValidityPasswordIcon = (isCheck: boolean) => {
    if (isCheck) {
      return <img src={greenCheckCircle.default} />
    } else {
      return <img src={blankCircle.default} />
    }
  }

  handleValidatePassword = (type: string) => {
    if (type === '1') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordEightDigitValidate)} <span style={webStyle.passwordSpan}>Min. 8 characters</span></>
    } else if (type === '2') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordOneNumberValidate)} <span style={webStyle.passwordSpan}>1 number</span></>
    } else if (type === '3') {
      return <>{this.handleValidityPasswordIcon(this.state.passwordCapitalCaseValidate)} <span style={webStyle.passwordSpan}>1 Capital letter</span></>
    } else {
      return <>{this.handleValidityPasswordIcon(this.state.passwordLowerCaseValidate)} <span style={webStyle.passwordSpan}>1 Lowercase letter</span></>
    }
  }
  
  handlePasswordViewIcon1 = () => {
    return !this.state.togglePasswordView1 ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={webStyle.passwordIcon} onClick={this.handleTogglePassword1} /> : <VisibilityOutlinedIcon style={webStyle.passwordIcon} onClick={this.handleTogglePassword1} data-testid="handleTogglePassword" />
  }

  handlePasswordViewError1 = () => {
   return this.state.currentPasswordError ? <CloseRoundedIcon style={{ color: "red", width: "24px", height: "24px", marginRight: "10px" }} /> : null 
  }

  handlePasswordViewError3 = () => {
    return this.state.confirmPasswordError ? <CloseRoundedIcon style={{ color: "red", width: "24px", height: "24px", marginRight: "10px" }} /> : null 
   }
 

  handlePasswordViewIcon2 = () => {
    return !this.state.togglePasswordView2 ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={webStyle.passwordIcon} onClick={this.handleTogglePassword2} /> : <VisibilityOutlinedIcon style={webStyle.passwordIcon} onClick={this.handleTogglePassword2} data-testid="handleTogglePassword" />
  }

  handlePasswordViewIcon3 = () => {
    return !this.state.togglePasswordView3 ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={webStyle.passwordIcon} onClick={this.handleTogglePassword3} /> : <VisibilityOutlinedIcon style={webStyle.passwordIcon} onClick={this.handleTogglePassword3} data-testid="handleTogglePassword" />
  }

    InitContent = () => <>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>{this.state.isForgotPassword ? "Forgot Password" : "Edit Profile"}</Typography>
            <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Required fields</Typography>
            {!this.state.isForgotPassword && 
            <>
                        <Box display="flex" gap="24px" justifyContent="center" marginTop='3px' marginBottom='20px'>
                <Box style={webStyle.boxStyle}>
                  <Typography style={webStyle.labelStyle}>First Name</Typography>
                  <TextField
                    data-testid="handleFirstNameChange"
                    placeholder="First name"
                    style={webStyle.textInput}
                    value={this.state.firstName}
                    onChange={this.handleFirstNameChange}
                  />
                </Box>
                <Box style={webStyle.boxStyle}>
                  <Typography style={webStyle.labelStyle}>Last Name</Typography>
                  <TextField
                    data-testid="handleLastNameChange"
                    placeholder="Last name"
                    style={webStyle.textInput}
                    value={this.state.lastName}
                    onChange={this.handleLastNameChange}
                  />
                </Box>
              </Box>

              <div style={{width:"100%",height: 1, backgroundColor: "#94A3B8", marginBlock: "20px 25px"}}></div>

              <Box display="flex" justifyContent='center' marginBottom='20px'>
                <Box style={webStyle.boxStyle} width="100%">
                  <Typography style={webStyle.labelStyle}>Current Password</Typography>
                  <TextField
                    data-testid="handleChangeCurrentPassword"
                    type={this.returnPasswordType1()}
                    placeholder="Confirm your password"
                    fullWidth
                    style={webStyle.textInput}
                    value={this.state.currentPasswordText}
                    onChange={this.handleChangeCurrentPassword}
                    InputProps={{ endAdornment: <>{this.handlePasswordViewError1()}{this.handlePasswordViewIcon1()}</> }}
                    inputProps={{ maxLength: 64 }}
                  />
                    {this.state.currentPasswordError && <Typography style={{marginTop:"5px", fontSize: "14px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.currentPasswordError}</Typography>}
                </Box>
              </Box>
              </>
            }

              <Box marginTop={this.state.isForgotPassword ? "80px" : "0px"} display="flex" justifyContent='center' marginBottom='5px'>
                <Box style={webStyle.boxStyle} width="100%">
                  <Typography style={webStyle.labelStyle}>New Password</Typography>
                  <TextField
                    data-testid="handleChangePassword"
                    type={this.returnPasswordType2()}
                    placeholder="Confirm your new password"
                    fullWidth
                    style={webStyle.textInput}
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    InputProps={{ endAdornment: this.handlePasswordViewIcon2() }}
                    inputProps={{ maxLength: 64 }}
                  />

                </Box>
              </Box>
              <Box width="30rem" display='flex' alignItems='center' marginBottom='20px'>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('1')}</Typography>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('2')}</Typography>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('3')}</Typography>
                <Typography style={webStyle.passwordValidate}>{this.handleValidatePassword('4')}</Typography>
              </Box>


              <Box display="flex" justifyContent='center' marginBottom={this.state.isForgotPassword ? "80px" : "20px"}>
                <Box style={webStyle.boxStyle} width="100%">
                  <Typography style={webStyle.labelStyle}>Confirm New Password</Typography>
                  <TextField
                    data-testid="handleChangeNewPassword"
                    type={this.returnPasswordType3()}
                    placeholder="Confirm your new password"
                    fullWidth
                    style={webStyle.textInput}
                    value={this.state.confirmPasswordText}
                    onChange={this.handleChangeConfirmPassword}
                    InputProps={{ endAdornment: <>{this.handlePasswordViewError3()}{this.handlePasswordViewIcon3()}</> }}
                    inputProps={{ maxLength: 64 }}
                  />
                    {this.state.confirmPasswordError && <Typography style={{marginTop:"5px", fontSize: "14px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.confirmPasswordError}</Typography>}

                </Box>
              </Box>
        
              <StyledButtonGroup>
                 <Button className='cancel-btn' variant="outlined" onClick={this.handleCloseModal}>
                    Cancel
                 </Button>
                 <Button className='create-btn' variant="contained" color="primary" onClick={() => this.state.isForgotPassword ? this.confirmForgotPassword() : this.handleUpdateUser()}>
                    Update
                 </Button>
              </StyledButtonGroup>
        </div>
        </>

    ForgotPasswordContent = () => <>
    <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Forgot Password</Typography>
        <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Required fields</Typography>
        
        <div style={{display: 'flex', alignItems: "center", marginBlock: "35px", flexDirection: "column"}}>
        <Typography style={{textAlign:"center", fontSize: "30px", fontWeight: 700, color: "#00184C", margin: 0, width: "337px"}}>Forgot password</Typography>
        <Typography style={{width: "300px", textAlign:"center", fontSize: "16px", fontWeight: 400, color: "#00184C", margin: 0}}>Please enter your email address to request a password reset.</Typography>
        </div>

        <Box display="flex" justifyContent='center' marginBottom='20px'>
                <Box style={webStyle.boxStyle} width="100%">
                  <Typography style={webStyle.labelStyle}>Email</Typography>
                  <TextField
                    data-testid="handleEmailPassword"
                    type="text"
                    placeholder="Enter your email"
                    fullWidth
                    style={webStyle.textInput}
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    inputProps={{ maxLength: 64 }}
                  />
                    {this.state.currentPasswordError && <Typography style={{marginTop:"5px", fontSize: "14px", fontWeight: 400, color: "#DC2626", textAlign: "end" }}>{this.state.currentPasswordError}</Typography>}
                </Box>
              </Box>

          <StyledButtonGroup style={{justifyContent: "center"}}>
             <Button disabled={!this.state.email.length} style={{width: "207px", alignSelf: "center", marginTop: "50px"}} className='cancel-btn' variant="outlined" onClick={this.sendForgotPasswordRequest}>
                Send request
             </Button>
          </StyledButtonGroup>
    </div>
    </>
  // Customizable Area End

  render() {
    // Customizable Area Start


    return (
      <Layout navigation={this.props.navigation} currentRoute='Profile' updatedProfile={this.state.profile}>
     {(this.state.profile || this.isTest) && <StyledContainer>
        <img src={profileBg.default} style={{width:"calc(100% + 40px)", aspectRatio: 4.71, marginInline: "-20px", marginTop: "-20px", display: "inline-flex" }}></img>
        <div style={{width: "445px", height: "621px", color: "#00184C", background: "linear-gradient(180deg, #FFFFFF 0%, #EBEBEB 100%)", borderRadius: "8px", 
        boxShadow: "0px 2px 8px 0px #00000014", marginTop: "-80px", alignSelf: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Avatar className="avatar">{`${this.state.profile?.attributes?.first_name[0]}${this.state.profile?.attributes?.last_name[0]}`}</Avatar>
            <div className='active'>ACTIVE</div>

            <div style={{display:"flex", flex:1, paddingBlock: "10px 30px", flexDirection:"column", alignItems:"center", justifyContent: "space-around"}}>
            <Typography style={{fontSize: "18px", fontWeight: 400}}>{this.state.profile?.attributes?.organization?.company_name ?? ""}</Typography>

            <div style={{display:"flex", flexDirection: "column", alignItems:"center"}}>
            <Typography style={{fontSize: "24px", fontWeight: 700}}>{`${this.state.profile?.attributes?.first_name} ${this.state.profile?.attributes?.last_name}`}</Typography>
            <Typography style={{fontSize: "18px", fontWeight: 400}}>{this.state.profile?.attributes?.role_name === "Administrator" ? "Admin" : "Regular User"}</Typography>
            </div>

            <div style={{display:"flex", flexDirection: "column", alignItems:"center"}}>
            <Typography style={{fontSize: "16px", fontWeight: 700}}>Email:</Typography>
            <Typography style={{fontSize: "16px", fontWeight: 400}}>{this.state.profile?.attributes?.email}</Typography>
            </div>

            <EditButton onClick={() => {this.setState({isVisibleModal:true})}}><img src={icPen.default} style={{width: "18px", height: "18px"}}/>Edit Profile</EditButton>

            <Typography style={{fontSize: "12px", fontWeight: 600}} onClick={() => this.setState({isVisibleForgotPasswordModal: true})}>Forgot your password?</Typography>
            </div>

        </div>
      </StyledContainer>
      }

    <Modal
     slotProps={{
      backdrop: {
        sx: {
          backgroundColor: '#00184CB2', 
          backdropFilter: 'blur(10px)', // Blur effect
          transition: 'all 0.5s ease', // Smooth animation
          marginLeft: "234px"
        },
      },
    }}
    open={this.state.isVisibleModal} onClose={this.handleCloseModal}>
      <StyledBox>
  
        {this.InitContent()}
      <img src={ic_back.default} style={{cursor:"pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px"}} />
      </StyledBox>
    </Modal>

    <Modal
     slotProps={{
      backdrop: {
        sx: {
          backgroundColor: '#00184CB2', 
          backdropFilter: 'blur(10px)', // Blur effect
          transition: 'all 0.5s ease', // Smooth animation
          marginLeft: "234px"
        },
      },
    }}
    open={this.state.isVisibleForgotPasswordModal} onClose={this.handleForgotPasswordCloseModal}>
      <StyledBox>
  
        {this.ForgotPasswordContent()}
      <img onClick={this.handleForgotPasswordCloseModal} src={ic_back.default} style={{cursor:"pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px"}} />
      </StyledBox>
    </Modal>

    <Snackbar
      open={this.state.isToastOpen}
      autoHideDuration={5000} 
      onClose={this.handleCloseToast}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
    >
      <Alert
        onClose={this.handleCloseToast}
        icon={false}
        style={{
          backgroundColor: "#E6FAF0", 
          color: "#000000",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderLeft: "4px solid #4CAF50",
        }}
        action={
          <IconButton size="small" aria-label="close" onClick={this.handleCloseToast}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        }
      >
        <div contentEditable='true' dangerouslySetInnerHTML={{ __html: this.state.toastContent }}></div>
      </Alert>
    </Snackbar>
    </Layout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 'calc(50% + 234px / 2)', // Adjusting for the sidebar width
  transform: 'translate(-50%, -50%)',
  width: "580px",
  boxShadow: theme.shadows[5],
  padding: "25px",
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  background: "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(235, 235, 235, 0.9) 100%)",
  border: "3px solid #FFFFFFE5",
  borderRadius: "16px",
  paddingInline: "60px",
  boxSizing: "border-box",
  minHeight: "650px",

  "&:focus-visible": {
    outline: "none",
  },

  "@media (max-height: 720px)": {
    minHeight: "unset",
    scale: 0.85,
    marginTop: "-50px",
    marginLeft: "-32px",
    paddingInline: "60px",
  },

  "& .requireField": {
    marginBottom: "32px",
    
    "@media (max-height: 720px)": {
      marginBottom: "0px",
    },
  },

  "& .cancel-btn": {
    border: "1px solid #00184C",
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    textTransform: "none",
    color: "#00184C",
    height: "44px",
    fontWeight: 700,
    maxWidth: "210px",
  },

  "& .create-btn": {
    display: "flex",
    flex: 1,
    background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
    borderRadius: "24px",
    textTransform: "capitalize",
    color: "white",
    height: "44px",
    fontWeight: 700,
    maxWidth: "210px",
  },

  "& .textField": {
    border: "1px solid #334155",
    backgroundColor: "white",
    borderRadius: "8px", 
    marginTop: "10px",

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  }, 
}));

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "10px 16px 10px 16px",
  gap: "8px",
  borderRadius: "26px",
  background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
  color: "white",
  fontWeight: 700,
  fontSize: "16px"
}));

const StyledButtonGroup = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginBottom: "10px",
    justifyContent: 'space-between',
    marginTop: "10px",
    gap: "15px",
  
    "@media (max-height: 720px)": {
      gap: "10px",
      marginTop: theme.spacing(1),
      marginBottom: "15px",
    },
  }));

const webStyle = {
    boxStyle: {
      display: "flex",
      flexDirection: "column" as "column",
      flex: 1,
    },
    logoText: {
      fontWeight: 700,
      fontSize: '30px',
      paddingTop: '35px',
      paddingBottom: '8px',
      height: "40px",
      color: "#22223E",
      textAlign: 'center' as 'center',
      fontStyle: 'inter',
    },
    signupText: {
      color: '#334155',
      textAlign: 'center' as 'center',
      fontSize: "16px",
      fontStyle: 'inter',
      fontWeight: 400,
      lineHeight: '24px',
  
    },
    helpIcon: {
      position: "absolute" as "absolute",
      right: "18.33px",
      top: "20.33px",
      width: "23.33px",
      height: '23.33px'
    },
    textInput: {
      border: "1px solid #CBD5E1",
      backgroundColor: "white",
      borderRadius: "8px"
    },
    labelStyle: {
      color: '#334155',
      fontSize: '14px',
      fontWeight: 700 as 700,
      paddingBottom: '4px',
    },
    passwordIcon: {
      color: '#CBD5E1', cursor: "pointer"
    },
    passwordValidate: {
      color: '#22223E',
      fontSize: "12px",
      fontWeight: 400 as 400,
      height: "18px",
      display: 'flex',
      alignItems: "center"
    },
    passwordSpan: {
      paddingTop: '2px',
      paddingLeft: '6px',
      paddingRight: "10px"
    },
    consentCheckbox: {
      display: 'flex',
      justifyContent: 'center',
      alignItem: 'center',
      color: "#334155"
    },
    createButton: {
      width: '270px',
      height: '44px',
      border: '2px solid #00184C',
      borderRadius: '24px',
      color: '#00184C',
      textContent: 'center',
      textTransform: "capitalize" as "capitalize"
    },
    activeCreateButton: {
      color: 'white',
      backgroundColor: '#00184C'
    },
    acceptBtn: {
      marginTop: '40px',
      width: '207',
      height: '44px',
      border: '2px solid #00184C',
      borderRadius: '24px',
      color: '#00184C',
      textContent: 'center',
      fontWeight: 700 as 700,
      textTransform: "capitalize" as "capitalize"
    },
    acceptBtnCompleted: {
      backgroundColor: '#00184C',
      color: 'white'
    },
    consentText: {
      color: "#334155",
      fontSize: "14px",
      paddingLeft: "6px"
    },
    circleTick: {
      marginTop: '30px',
      marginBottom: '20px'
    },
    goLoginbtn: {
      marginTop: '40px',
      width: '207',
      height: '44px',
      border: '2px solid #00184C',
      borderRadius: '24px',
      textContent: 'center',
      fontWeight: 700 as 700,
      textTransform: "capitalize" as "capitalize",
      backgroundColor: '#00184C',
      color: 'white',
      cursor: "pointer"
    }
  }
  
// Customizable Area End
