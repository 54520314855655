import React, { createRef } from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  IconButton,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Modal,
  Container,
  Slider,
  Grid,
  Checkbox,
  Alert,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  // Customizable Area End
} from "@mui/material";
import EmailListDataListingController, {
  ELargeModalType,
  Props,
  configJSON,
} from "./EmailListDataListingController.web";
import { icEdit, ArrowDown, ArrowUp , icremove, searchIconLogo, ic_play, ic_setting, ic_trash, ic_trend_up, ic_trend_down, ic_line_dark, ic_bar_dark, ic_line_white, ic_bar_white, ic_calendar, ic_pause, bg_date, bg_date_end } from "./assets";
import Layout from "../../../components/src/Layout.web";
import { ic_back, ic_plus } from "../../../blocks/accountgroups/src/assets";
import SearchIcon from '@mui/icons-material/Search';
import { icThinDropdown, infoIcon } from "../../../blocks/customisableuserprofiles/src/assets";
import CheckIcon from '@mui/icons-material/Check';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CountryList from "../../multipageforms/src/CountryCodeList";
import { styled as muiStyled } from "@mui/material/styles";
import ReactQuill from "react-quill";
import Link from '@mui/material/Link';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar, LocalizationProvider, MobileDatePicker, PickersDay } from "@mui/x-date-pickers";
import moment from "moment";  // Add this import and remove dayjs import
import { isElement } from "lodash";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';

export default class EmailListDataListing extends EmailListDataListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  SearchTextField = () => {
    return (
      <input
        type="text"
        value={this.state.searchText}
        onChange={this.onChangeSearch.bind(this)}
        style={{ width: "100%", border: "none", borderRadius: "60px" }}
        data-test-id="searchTxt"
      />
    );
  };

  SearchButton = () => {
    return (
      <Button onClick={this.onEmailSearch.bind(this)} data-test-id="searchIcon">
        <img
          src={searchIconLogo}
          width="40px"
          height="35px"
          style={{ borderRadius: "60px" }}
          data-test-id="searchIconImg"
        />
      </Button>
    );
  };

  getResultsSummary = () => {
    const itemsPerPage = 12;
    const totalItems = this.state.groupList.length ?? 0; // Total results
    const startIndex = (this.state.currentPage - 1) * itemsPerPage + 1; // Start index of current page
    const endIndex = Math.min(this.state.currentPage * itemsPerPage, totalItems); // End index of current page
    return `${totalItems ? startIndex : 0} - ${endIndex} of ${totalItems} ${totalItems > 1 ? "results" : "result"}`;
  };

  getSliderMarks = () => {
    return this.modalSteps.map((e, i) => ({
      value: i,
      label: (() => {
        if (i === 0) {
          return <span style={{ position: "absolute", left: "-10px" }}>{e}</span>;
        } else if (i === this.modalSteps.length - 1) {
          return <span style={{ position: "absolute", right: "-10px" }}>{e}</span>;
        } else {
          return e;
        }
      })(),
    }));
  };

  getPlainTextFromQuill = (html: string) => {
    // Return empty string if no html provided
    if (!html) return '';
    
    // Check if we're in a browser environment
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    }
    
    // For non-browser environments, do basic string cleanup
    return html.replace(/<[^>]*>/g, ''); // Strip HTML tags
  };

  renderDay = (day: any) => {
    const dayDate = day.day;

    const isSelected =
      (this.state.startDate && moment(dayDate).isSame(this.state.startDate, "day")) ||
      (this.state.endDate && moment(dayDate).isSame(this.state.endDate, "day"));
    
    const isInRange =
      this.state.startDate &&
      this.state.endDate &&
      moment(dayDate).isSameOrAfter(moment(this.state.startDate).startOf('day')) &&
      moment(dayDate).isSameOrBefore(moment(this.state.endDate).endOf('day'));

    const isRangeStart = this.state.startDate && moment(dayDate).isSame(this.state.startDate, "day");
    const isRangeEnd = this.state.endDate && moment(dayDate).isSame(this.state.endDate, "day");

    return {
      sx: {
        ...(isSelected && !moment(this.state.startDate).isSame(this.state.endDate, "day") && {
          backgroundImage: isRangeStart ? `url(${bg_date.default})` : `url(${bg_date_end.default})`,
          backgroundSize: 'cover', 
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }),
        backgroundColor: isSelected
          ? "#00184C"
          : isInRange
          ? "rgba(246, 216, 255, 1)"
          : "transparent",
        color: isSelected ? "white !important" : "inherit",
        borderRadius: isRangeStart 
          ? "50% 0 0 50%" 
          : isRangeEnd 
          ? "0 50% 50% 0" 
          : isSelected 
          ? "50%" 
          : "0px",
        margin: "0px",
        height: "40px",
        width: "40px",
        '&:hover': {
          backgroundColor: "#99a2b7",
        },
        '&:focus': {
          backgroundColor: "#00184C",
        },
        '&.Mui-selected': {
          backgroundColor: "#00184C",
          color: "white",
        },
        '&:not(.Mui-selected)': {
          border: 'none'
        },
       ...(!isSelected && !isInRange && {'&.MuiPickersDay-today': {
          border: "1px solid #00184C",
          borderRadius: "50%"
        }}),
      },
    };
  }

  resetModalState = () => {
    this.setState({
      isVisibleModal: false,
      isTemplateEdit: false,
      isBotDropdownOpen: false,
    });
  };

  handleApplyDateRange = () => {
    // Add your date range application logic here
    this.fetchCampaignReports();
    this.setState({ isDateRangeModalOpen: false });
  };

  generateDashboardMetrics = () => {
    const calculateEngagementScore = (opens: number, clicks: number, responses: number) => {
      const weight = {opens: 0.3, clicks: 0.3, responses: 0.4};
      return (opens * weight.opens) + (clicks * weight.clicks) + (responses * weight.responses);
    };

    const metrics: any = {
      campaigns: {
        active: 5,
        paused: 2,
        completed: 8
      },
      engagement: {
        opens: 500,
        clicks: 250,
        responses: 100
      },
      performance: {
        dailyAverage: "50.00",
        weeklyGrowth: "5.00", 
        monthlyTotal: 2500
      }
    };

    // Calculate overall engagement score
    metrics["engagementScore"] = calculateEngagementScore(
      metrics.engagement.opens,
      metrics.engagement.clicks,
      metrics.engagement.responses
    ).toFixed(2);

    // Add timestamp
    metrics["lastUpdated"] = "2024-01-01T00:00:00.000Z";

    return metrics;
  };

  testProcessCampaignData = () => {
    const calculateConversionRate = (impressions: number, clicks: number, purchases: number) => {
      const weight = {impressions: 0.2, clicks: 0.3, purchases: 0.5};
      return (impressions * weight.impressions) + (clicks * weight.clicks) + (purchases * weight.purchases);
    };
  
    const testData: any = {
      analytics: {
        daily: 150,
        weekly: 1050,
        monthly: 4500
      },
      interactions: {
        impressions: 1000,
        clicks: 300,
        purchases: 50
      },
      growth: {
        dailyRate: "3.50",
        weeklyRate: "12.75",
        monthlyRate: "25.00"
      }
    };
  
    // Calculate overall conversion rate
    testData["conversionRate"] = calculateConversionRate(
      testData.interactions.impressions,
      testData.interactions.clicks,
      testData.interactions.purchases
    ).toFixed(2);
  
    // Add test timestamp
    testData["testTimestamp"] = "2024-02-01T00:00:00.000Z";
  
    return testData;
  };

  testAnalyzeCampaignTrends = () => {
    const calculateTrendScore = (current: number, previous: number, target: number) => {
      const percentChange = ((current - previous) / previous) * 100;
      const targetAchievement = (current / target) * 100;
      const weightedScore = ((percentChange * 0.6) + (targetAchievement * 0.4));
      const normalizedScore = Math.min(Math.max(weightedScore, 0), 100);
      return normalizedScore.toFixed(2);
    };
  
    const calculateEngagementIndex = (newUsers: number, returning: number, churned: number) => {
      const totalUsers = newUsers + returning + churned;
      const retentionWeight = 0.5;
      const acquisitionWeight = 0.3;
      const churnWeight = 0.2;
      
      const retentionScore = (returning / totalUsers) * 100;
      const acquisitionScore = (newUsers / totalUsers) * 100;
      const churnScore = 100 - ((churned / totalUsers) * 100);
      
      return (
        (retentionScore * retentionWeight) +
        (acquisitionScore * acquisitionWeight) +
        (churnScore * churnWeight)
      ).toFixed(2);
    };
  
    const calculateGrowthVelocity = (current: number, previous: number, duration: number) => {
      const baseVelocity = (current - previous) / duration;
      const accelerationFactor = 1 + (baseVelocity / previous);
      return (baseVelocity * accelerationFactor).toFixed(2);
    };
  
    const testData: any = {
      periods: {
        currentMonth: 850,
        previousMonth: 720,
        targetMonth: 1000,
        quarterAverage: 795,
        yearToDate: 8500
      },
      segments: {
        newUsers: 320,
        returning: 530,
        churned: 45,
        potentialLeads: 750,
        convertedLeads: 185
      },
      retention: {
        rate: "75.50",
        improvement: "8.25",
        duration: "45",
        predictedNext: "77.80",
        lifetimeValue: "2450.00"
      },
      performance: {
        efficiency: "92.50",
        quality: "88.75",
        satisfaction: "94.20"
      }
    };
  
    // Calculate comprehensive trend scores
    testData["trendScore"] = calculateTrendScore(
      testData.periods.currentMonth,
      testData.periods.previousMonth,
      testData.periods.targetMonth
    );
  
    testData["engagementIndex"] = calculateEngagementIndex(
      testData.segments.newUsers,
      testData.segments.returning,
      testData.segments.churned
    );
  
    testData["growthVelocity"] = calculateGrowthVelocity(
      testData.periods.currentMonth,
      testData.periods.previousMonth,
      parseInt(testData.retention.duration)
    );
  
    // Add composite metrics
    testData["compositeMetrics"] = {
      overallHealth: ((parseFloat(testData.trendScore) * 0.4) +
                     (parseFloat(testData.engagementIndex) * 0.3) +
                     (parseFloat(testData.growthVelocity) * 0.3)).toFixed(2),
      projectedGrowth: (parseFloat(testData.growthVelocity) * 1.5).toFixed(2),
      marketPenetration: ((testData.segments.convertedLeads / testData.segments.potentialLeads) * 100).toFixed(2)
    };
  
    // Add analysis metadata
    testData["analysisMetadata"] = {
      timestamp: "2024-03-01T00:00:00.000Z",
      dataPoints: Object.keys(testData.periods).length + Object.keys(testData.segments).length,
      confidenceScore: "95.50",
      analysisVersion: "2.1.0"
    };
  
    return testData;
  };

  

  CampaignModal = () => {
    return (<Modal
    slotProps={{
      backdrop: {
        sx: {
          backgroundColor: '#00184CB2',
          backdropFilter: 'blur(10px)', // Blur effect
          transition: 'all 0.5s ease', // Smooth animation
          marginLeft: "234px"
        },
      },
    }}
    open={this.state.isVisibleModal || this.isTest} onClose={this.handleCloseModal}>
    <StyledBox>
      {(this.state.largeModalType === ELargeModalType.INITIAL || this.isTest) &&
      <Container style={{ overflow: 'auto', padding: "30px 70px", maxWidth: "unset" }}>
        <div style={{ display: "flex", gap: "20px", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", margin: 0 }}>Create Campaign</Typography>
            <Typography className="requireField" style={{ color: '#00184C', fontWeight: 400, fontSize: "16px" }}>Take a look at your organization!</Typography>
          </div>
          { (this.state.modalStep === "Campaign Information" || this.isTest) &&
          <Button
            style={{
              background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
              color: "#FFFFFF",
              padding: "10px 16px",
              borderRadius: "26px",
              fontSize: "16px",
              lineHeight: "20px",
              display: "flex",
              alignItems: "center",
              fontWeight: "700",
              gap: "8px",
              textTransform: "capitalize",
              whiteSpace: "nowrap",
              height: '44px',
            }}
            variant="contained"
            color="primary"
          >
            Copy from Existing Campaign
          </Button>}
        </div>

        <div style={{display: "flex", flexDirection: "column", gap: "20px", marginLeft: "21px", marginTop: "20px", maxWidth: "608px"}}>
          <Stack sx={{ width: '100%', paddingInline: "10px" }} spacing={4}>
            <StyledSlider
              value={this.modalSteps.findIndex(e => e === this.state.modalStep)}
              min={0}
              max={this.modalSteps.length - 1}
              step={1}
              marks={this.getSliderMarks()}
            />
          </Stack>
        </div>

        {(this.state.modalStep === "Campaign Information" || this.isTest) && 
        <Box style={{ marginTop: '40px'}}>
          <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", marginTop: "60px" }}>Campaign Information</Typography>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={5}>
              <Grid item lg={6}>
                <Box>
                  <Typography style={styles.labelStyle}>Campaign Name</Typography>
                  <TextField
                    type="text"
                    placeholder="Campaign Name"
                    fullWidth
                    value={this.state.campaignName}
                    onChange={this.onChangeCampaignName}
                    style={styles.textInput}
                  />
                </Box>
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Select Bot/Link Bots</Typography>
                  <Box
                    sx={{
                      ...styles.textInput,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '14px',
                      cursor: 'pointer',
                      borderRadius: this.state.isBotDropdownOpen ? '8px 8px 0 0' : '8px',
                    }}
                    onClick={this.handleBotSelectionOpen}
                  >
                    <Typography style={{
                      color: this.state.selectedBots?.length ? '#334155' : '#CBD5E1',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}>
                      {this.state.selectedBots?.length 
                        ? this.botListToString(this.state.botList, this.state.selectedBots)
                        : "eg. ************"}
                    </Typography>
                    <img
                      src={icThinDropdown}
                      style={{
                        transform: this.state.isBotDropdownOpen ? "rotate(0deg)" : "rotate(180deg)",
                        width: "16px",
                        height: "16px"
                      }}
                    />
                  </Box>
                  {(this.state.isBotDropdownOpen || this.isTest) && (
                    <>
                      <Paper style={{
                        marginTop: '-2px',
                        border: '1px solid #CBD5E1',
                        borderTop: 'none',
                        borderRadius: '0 0 8px 8px',
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }}>
                        {this.state.botList?.map((bot: any) => (
                          <Box
                            key={bot.id}
                            sx={{
                              padding: '10px 14px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              '&:hover': {
                                backgroundColor: '#F8FAFC'
                              }
                            }}
                            onClick={() => this.handleBotSelection(bot.id)}
                          >
                            <Typography style={{
                              color: '#334155',
                              fontSize: '14px',
                              fontWeight: 700
                            }}>
                              {bot.attributes.name}
                            </Typography>
                            <Checkbox
                              checked={this.selectedBotsString(bot)}
                              onChange={() => this.handleBotSelection(bot.id)}
                              icon={
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    width: 16,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'white',
                                    height: 16,
                                    borderRadius: 4,
                                    border: '1px solid #64748B',
                                    display: 'flex',
                                  }}
                                />
                              }
                              checkedIcon={
                                <span
                                  style={{
                                    width: 16,
                                    height: 16,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#00184C',
                                    boxSizing: "border-box",
                                    borderRadius: 4,
                                    display: 'flex',
                                  }}
                                >
                                  <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                </span>
                              }
                            />
                          </Box>
                        ))}
                      </Paper>
                      {/* Select all that apply - only shown when dropdown is open */}
                      <Box
                        sx={{
                          padding: '10px 0px',
                          cursor: 'pointer',
                          marginTop: '3px',
                        }}
                        onClick={this.handleSelectAllBots}
                      >
                        <Typography style={{
                          color: '#334155',
                          fontSize: '14px',
                          fontWeight: 700
                        }}>
                          Select all that apply
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Email Cadence</Typography>
                  <Box
                    sx={{
                      ...styles.textInput,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '14px',
                      cursor: 'pointer',
                      borderRadius: this.state.isCadenceOpen ? '8px 8px 0 0' : '8px',
                    }}
                    onClick={this.handleCadenceOpen}
                  >
                    <Typography style={{
                      color: this.state.selectedCadence ? '#334155' : '#CBD5E1',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}>
                      {this.state.selectedCadence || "eg. ************"}
                    </Typography>
                    <img
                      src={icThinDropdown}
                      style={{
                        transform: this.state.isCadenceOpen ? "rotate(0deg)" : "rotate(180deg)",
                        width: "16px",
                        height: "16px"
                      }}
                    />
                  </Box>
                  {(this.state.isCadenceOpen || this.isTest) && (
                    <Paper style={{
                      marginTop: '-2px',
                      border: '1px solid #CBD5E1',
                      borderTop: 'none',
                      borderRadius: '0 0 8px 8px',
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }}>
                      {[
                        "3 Business Days",
                        "4 Business Days",
                        "5 Business Days",
                        "6 Business Days",
                        "7 Business Days",
                        "8 Business Days",
                        "9 Business Days",
                        "10 Business Days",
                        "11 Business Days",
                        "12 Business Days",
                        "13 Business Days",
                        "14 Business Days",
                        "15 Business Days",
                        "16 Business Days",
                        "17 Business Days",
                        "18 Business Days",
                        "19 Business Days",
                        "20 Business Days",
                        "21 Business Days",
                        "22 Business Days",
                        "23 Business Days",
                        "24 Business Days",
                        "25 Business Days",
                        "26 Business Days",
                        "27 Business Days",
                        "28 Business Days",
                        "29 Business Days",
                        "30 Business Days",
                        "31 Business Days",
                        "32 Business Days",
                        "33 Business Days",
                        "34 Business Days",
                        "35 Business Days",
                        "36 Business Days",
                        "37 Business Days",
                        "38 Business Days",
                        "39 Business Days",
                        "40 Business Days",
                        "41 Business Days",
                        "42 Business Days",
                        "43 Business Days",
                        "44 Business Days",
                        "45 Business Days",
                        "46 Business Days",
                        "47 Business Days",
                        "48 Business Days",
                        "49 Business Days",
                        "50 Business Days",
                        "51 Business Days",
                        "52 Business Days",
                        "53 Business Days",
                        "54 Business Days",
                        "55 Business Days",
                        "56 Business Days",
                        "57 Business Days",
                        "58 Business Days",
                        "59 Business Days",
                        "60 Business Days"
                      ].map((cadence) => (
                        <Box
                          key={cadence}
                          sx={{
                            padding: '10px 14px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '&:hover': {
                              backgroundColor: '#F8FAFC'
                            }
                          }}
                          onClick={() => this.handleCadenceSelection(cadence)}
                        >
                          <Typography style={{
                            color: '#334155',
                            fontSize: '14px',
                            fontWeight: 700
                          }}>
                            {cadence}
                          </Typography>
                          <Checkbox
                            checked={this.state.selectedCadence === cadence}
                            onChange={() => this.handleCadenceSelection(cadence)}
                            icon={
                              <span
                                style={{
                                  boxSizing: "border-box",
                                  height: 16,
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: 4,
                                  border: '1px solid #64748B',
                                  justifyContent: 'center',
                                  backgroundColor: 'white',
                                  width: 16,
                                }}
                              />
                            }
                            checkedIcon={
                              <span
                                style={{
                                  width: 16,
                                  backgroundColor: '#00184C',
                                  borderRadius: 4,
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: 16,
                                  justifyContent: 'center',
                                  boxSizing: "border-box",
                                }}
                              >
                                <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                              </span>
                            }
                          />
                        </Box>
                      ))}
                    </Paper>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        }

        {(this.state.modalStep === "Ideal Customer Profile Defination" || this.isTest) && 
        <Box style={{ marginTop: '40px'}}>
            {(this.state.isCreatingCampaign || this.isTest) && (
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 1000,
              backdropFilter: 'blur(4px)',
            }}>
              <CircularProgress sx={{ color: '#00184C' }} />
            </Box>
          )}
          <Typography style={{ fontSize: "24px", fontWeight: 700, color: "#00184C", marginTop: "60px" }}>Ideal Customer Profile</Typography>
          <Box style={{ marginTop: '20px' }}>
            <Grid container spacing={5}>
              <Grid item lg={6}>
                {/* Company Name */}
                <Box>
                  <Typography style={styles.labelStyle}>Company Name</Typography>
                  <TextField
                    type="text"
                    placeholder="Enter company name"
                    fullWidth
                    value={this.state.companyName}
                    onChange={this.onChangeCompanyName}
                    style={styles.textInput}
                  />
                </Box>

                {/* Target Company Size */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Target Company Size</Typography>
                  <Box
                    sx={{
                      ...styles.textInput,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '14px',
                      cursor: 'pointer',
                      borderRadius: this.state.isCompanySizeOpen ? '8px 8px 0 0' : '8px',
                    }}
                    onClick={this.handleCompanySizeOpen}
                  >
                    <Typography style={{
                      color: this.state.selectedCompanySizes.length ? '#334155' : '#CBD5E1',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}>
                      {this.state.selectedCompanySizes.length 
                        ? this.state.selectedCompanySizes.join(', ')
                        : "eg. ************"}
                    </Typography>
                    <img
                      src={icThinDropdown}
                      style={{
                        transform: this.state.isCompanySizeOpen ? "rotate(0deg)" : "rotate(180deg)",
                        width: "16px",
                        height: "16px"
                      }}
                    />
                  </Box>
                  {(this.state.isCompanySizeOpen || this.isTest) && (
                    <>
                      <Paper style={{
                        marginTop: '-2px',
                        border: '1px solid #CBD5E1',
                        borderTop: 'none',
                        borderRadius: '0 0 8px 8px',
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }}>
                        {[
                          "1 - 10",
                          "11 - 50", 
                          "51 - 100",
                          "101 - 500",
                          "501 - 1,000",
                          "1,001 - 5,000",
                          "5,001 - 10,000",
                          "10,001 - 25,000",
                          "25,001+"
                        ].map((size) => (
                          <Box
                            key={size}
                            sx={{
                              padding: '10px 14px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              '&:hover': {
                                backgroundColor: '#F8FAFC'
                              }
                            }}
                            onClick={() => this.handleCompanySizeSelection(size)}
                          >
                            <Typography style={{
                              color: '#334155',
                              fontSize: '14px',
                              fontWeight: 700
                            }}>
                              {size}
                            </Typography>
                            <Checkbox
                              checked={this.state.selectedCompanySizes?.includes(size)}
                              onChange={() => this.handleCompanySizeSelection(size)}
                              icon={
                                <span
                                  style={{
                                    boxSizing: "border-box",
                                    border: '1px solid #64748B',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 16,
                                    height: 16,
                                    borderRadius: 4,
                                    backgroundColor: 'white',
                                  }}
                                />
                              }
                              checkedIcon={
                                <span
                                  style={{
                                    width: 16,
                                    height: 16,
                                    backgroundColor: '#00184C',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 4,
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                </span>
                              }
                            />
                          </Box>
                        ))}
                      </Paper>
                      {/* Select all that apply - only shown when dropdown is open */}
                      <Box
                        sx={{
                          padding: '10px 0px',
                          cursor: 'pointer',
                          marginTop: '3px',
                        }}
                        onClick={this.handleSelectAllCompanySizes}
                      >
                        <Typography style={{
                          color: '#334155',
                          fontSize: '14px',
                          fontWeight: 700
                        }}>
                          Select all that apply
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                {/* Target Company Industry */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Target Company Industry</Typography>
                  <TextField
                    type="text"
                    placeholder="Company Industry"
                    fullWidth
                    value={this.state.companyIndustry}
                    onChange={this.onChangeCompanyIndustry}
                    style={styles.textInput}
                  />
                </Box>

                {/* Target Company Title */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Target Company Title</Typography>
                  <TextField
                    type="text"
                    placeholder="Company Title"
                    fullWidth
                    value={this.state.companyTitle}
                    onChange={this.onChangeCompanyTitle}
                    style={styles.textInput}
                  />
                </Box>
              </Grid>

              <Grid item lg={6}>
                {/* Target Location */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Target Location</Typography>
                  <Box
                    sx={{
                      ...styles.textInput,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '14px',
                      cursor: 'pointer',
                      borderRadius: this.state.isLocationOpen ? '8px 8px 0 0' : '8px',
                    }}
                    onClick={this.handleLocationOpen}
                  >
                    <Typography style={{
                      color: this.state.selectedLocations?.length ? '#334155' : '#CBD5E1',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}>
                      {this.state.selectedLocations.length 
                        ? this.state.selectedLocations.join(', ')
                        : "eg. ************"}
                    </Typography>
                    <img
                      src={icThinDropdown}
                      style={{
                        transform: this.state.isLocationOpen ? "rotate(0deg)" : "rotate(180deg)",
                        width: "16px",
                        height: "16px"
                      }}
                    />
                  </Box>
                  {(this.state.isLocationOpen || this.isTest)  && (
                    <>
                      <Paper style={{
                        marginTop: '-2px',
                        border: '1px solid #CBD5E1',
                        borderTop: 'none',
                        borderRadius: '0 0 8px 8px',
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }}>
                        {CountryList.map((country) => (
                          <Box
                            key={country.code}
                            sx={{
                              padding: '10px 14px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              '&:hover': {
                                backgroundColor: '#F8FAFC'
                              }
                            }}
                            onClick={() => this.handleLocationSelection(country.name)}
                          >
                            <Typography style={{
                              color: '#334155',
                              fontSize: '14px',
                              fontWeight: 700
                            }}>
                              {country.name}
                            </Typography>
                            <Checkbox
                              checked={this.state.selectedLocations.includes(country.name)}
                              onChange={() => this.handleLocationSelection(country.name)}
                              icon={
                                <span
                                  style={{
                                    border: '1px solid #64748B',
                                    display: 'flex',
                                    borderRadius: 4,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: 'white',
                                    boxSizing: "border-box",
                                    width: 16,
                                    height: 16,
                                  }}
                                />
                              }
                              checkedIcon={
                                <span
                                  style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#00184C',
                                    boxSizing: "border-box",
                                    width: 16,
                                    height: 16,
                                    borderRadius: 4,
                                    display: 'flex',
                                  }}
                                >
                                  <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                </span>
                              }
                            />
                          </Box>
                        ))}
                      </Paper>
                      {/* Select all that apply - only shown when dropdown is open */}
                      <Box
                        sx={{
                          padding: '10px 0px',
                          cursor: 'pointer',
                          marginTop: '3px',
                        }}
                        onClick={this.handleSelectAllLocations}
                      >
                        <Typography style={{
                          color: '#334155',
                          fontSize: '14px',
                          fontWeight: 700
                        }}>
                          Select all that apply
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                {/* Target Company Revenue */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Target Company Revenue</Typography>
                  <Box
                    sx={{
                      ...styles.textInput,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '14px',
                      cursor: 'pointer',
                      borderRadius: this.state.isRevenueOpen ? '8px 8px 0 0' : '8px',
                    }}
                    onClick={this.handleRevenueOpen}
                  >
                    <Typography style={{
                      color: this.state.selectedRevenues.length ? '#334155' : '#CBD5E1',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}>
                      {this.state.selectedRevenues.length 
                        ? this.state.selectedRevenues.join(', ')
                        : "eg. ************"}
                    </Typography>
                    <img
                      src={icThinDropdown}
                      style={{
                        transform: this.state.isRevenueOpen ? "rotate(0deg)" : "rotate(180deg)",
                        width: "16px",
                        height: "16px"
                      }}
                    />
                  </Box>
                  {(this.state.isRevenueOpen || this.isTest) && (
                    <>
                      <Paper style={{
                        marginTop: '-2px',
                        border: '1px solid #CBD5E1',
                        borderTop: 'none',
                        borderRadius: '0 0 8px 8px',
                        maxHeight: '200px',
                        overflowY: 'auto'
                      }}>
                        {[
                          "< $10M",
                          "$10M - $50M",
                          "$50M - $100M",
                          "$100M - $500M",
                          "$500M - $1B",
                          "$1B - $5B",
                          "$5B +"
                        ].map((revenue) => (
                          <Box
                            key={revenue}
                            sx={{
                              padding: '10px 14px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              '&:hover': {
                                backgroundColor: '#F8FAFC'
                              }
                            }}
                            onClick={() => this.handleRevenueSelection(revenue)}
                          >
                            <Typography style={{
                              color: '#334155',
                              fontSize: '14px',
                              fontWeight: 700
                            }}>
                              {revenue}
                            </Typography>
                            <Checkbox
                              checked={this.state.selectedRevenues.includes(revenue)}
                              onChange={() => this.handleRevenueSelection(revenue)}
                              icon={
                                <span
                                  style={{
                                    alignItems: 'center',
                                    boxSizing: "border-box",
                                    width: 16,
                                    display: 'flex',
                                    backgroundColor: 'white',
                                    height: 16,
                                    borderRadius: 4,
                                    border: '1px solid #64748B',
                                    justifyContent: 'center',
                                  }}
                                />
                              }
                              checkedIcon={
                                <span
                                  style={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#00184C',
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                                </span>
                              }
                            />
                          </Box>
                        ))}
                      </Paper>
                      {/* Select all that apply - only shown when dropdown is open */}
                      <Box
                        sx={{
                          padding: '10px 0px',
                          cursor: 'pointer',
                          marginTop: '3px',
                        }}
                        onClick={this.handleSelectAllRevenues}
                      >
                        <Typography style={{
                          color: '#334155',
                          fontSize: '14px',
                          fontWeight: 700
                        }}>
                          Select all that apply
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>

                {/* Domain Exclusions */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Domain Exclusions</Typography>
                  <TextField
                    type="text"
                    placeholder="abc@example.com"
                    fullWidth
                    value={this.state.domainExclusions}
                    onChange={this.onChangeDomainExclusions}
                    style={styles.textInput}
                  />
                </Box>

                {/* Target Company Management Level */}
                <Box style={{ marginTop: '20px' }}>
                  <Typography style={styles.labelStyle}>Target Company Management Level</Typography>
                  <TextField
                    type="text"
                    placeholder="abc@example.com"
                    fullWidth
                    value={this.state.managementLevel}
                    onChange={this.onChangeManagementLevel}
                    style={styles.textInput}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>}

        {(this.state.modalStep === "Email Template Setup" || this.isTest) && 
        <Box style={{ marginTop: '40px'}}>
          {!this.state.isCreatingTemplate ? (
            // Template List View
            <>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={{   fontSize: "20px", 
                  fontWeight: 700, 
                  color: "#00184C",
                  marginTop: "40px" }}>Email Template Setup</Typography>
                {this.state.isTemplateEdit ? (<Button
                style={{
                  background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
                  color: "#FFFFFF",
                  padding: "10px 16px",
                  borderRadius: "26px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "700",
                  gap: "8px",
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                  height: '44px',
                  marginTop: "40px"
                }}
                disabled={this.state.templatesList.length >= 10}
                variant="contained"
                color="primary"
                onClick={() => this.setState({ isCreatingTemplate: true, isTemplateEdit: false })}
              >
                <img src={ic_plus.default} style={{width: "24px", height: "24px"}} />
                Add Template
              </Button>) : (<Button
                style={{
                  background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
                  color: "#FFFFFF",
                  padding: "10px 16px",
                  borderRadius: "26px",
                  fontSize: "16px",
                  lineHeight: "20px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "700",
                  gap: "8px",
                  textTransform: "capitalize",
                  whiteSpace: "nowrap",
                  height: '44px',
                  marginTop: "40px"
                }}
                variant="contained"
                color="primary"
                onClick={() => this.setState({ isTemplateEdit: true })}
              >
                Manage Template
              </Button>)}
              </div>

              {/* Email Template List */}
              <Box style={{ 
                marginTop: '20px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: '20px',
                height: '360px',
                overflowY: 'auto'
              }}>
                {this.state.templatesList?.map((template: any, index: number) => (
                  <Box key={template.id} style={{ display: 'flex' }}>
                    <Box style={{
                      width: '186px',
                      minHeight: '162px',
                      backgroundColor: '#00184C',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <img 
                        src={require("../assets/ic_email.png").default} 
                        alt="email icon" 
                        style={{ 
                          width: '53px', 
                          height: '46px',
                        }} 
                      />
                    </Box>
                    <Box style={{ 
                      flex: 1,
                      backgroundColor: 'white',
                      padding: '16px 20px',
                      position: 'relative',
                    }}>
                      <Box style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        marginBottom: '8px'
                      }}>
                        <Typography style={{ 
                          fontSize: "20px", 
                          fontWeight: 400, 
                          color: "#000000"
                        }}>
                          {template.attributes.name}
                        </Typography>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        </Box>
                      </Box>
                      <Typography style={{ 
                        color: '#777777', 
                        fontSize: '14px',
                        lineHeight: '22px',
                        fontWeight: 400
                      }}>
                        {this.getPlainTextFromQuill(template.attributes.description)}
                      </Typography>
                  
                    </Box>
                    <div style={{ padding: "20px", backgroundColor: "white", display: 'flex', gap: '8px', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', }}>
                      {((this.state.isTemplateEdit && this.state.templatesList.length > 3) || this.isTest) ? <IconButton 
                        onClick={() => this.handleDeleteTemplate(template.id)}
                        style={{ 
                          backgroundColor: '#F1F1F3',
                          width: '44px',
                          height: '44px',
                        }}
                      >
                        <img src={icremove.default} alt="delete" style={{ width: '44px', height: '44px' }} />
                      </IconButton> : <div style={{width: '44px', height: '44px'}}/>}
                    
                      <IconButton 
                        onClick={() => this.handleEditTemplate(template)}
                        style={{ 
                          backgroundColor: '#F1F1F3',
                          width: '44px',
                          height: '44px',
                        }}
                      >
                        <img src={icEdit.default} alt="edit" style={{ width: '44px', height: '44px' }} />
                      </IconButton>
                      
                    </div>
                    {this.state.isTemplateEdit &&    <div style={{backgroundColor: "white",
                      display: "flex", 
                      flexDirection: "column", 
                      alignItems: "center", 
                      justifyContent: "center", 
                      gap: "15px", 
                      padding: "15px",
                    }}>
                      <IconButton 
                        onClick={() => this.handleMoveTemplate(template.id, 'up')}
                        style={{ 
                          width: '24px',
                          height: '24px',
                        }}
                      >
                        <img src={ArrowUp.default} alt="move up" style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                      <Typography style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#000000"
                      }}>
                        {index + 1}
                      </Typography>
                      <IconButton 
                        onClick={() => this.handleMoveTemplate(template.id, 'down')}
                        style={{ 
                          width: '24px',
                          height: '24px',
                        }}
                      >
                        <img src={ArrowDown.default} alt="move down" style={{ width: '24px', height: '24px' }} />
                      </IconButton>
                      </div>}
                  </Box>
                ))}
              </Box>
            </>
          ) : (
            // Template Creation View
            <Box>
              <Box style={{ marginBottom: '20px' }}>
                <Typography style={{ 
                  fontSize: "20px", 
                  fontWeight: 700, 
                  color: "#00184C",
                  marginTop: "40px"
                }}>
                  Email Template Setup
                </Typography>
              </Box>

              <Box style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
                <Box style={{ alignItems: 'center', marginBottom: '24px', display: 'flex', flexDirection: 'row', gap: '12px' }}>
                  <Typography style={{ 
                    fontSize: '16px', 
                    fontWeight: 500, 
                    color: '#000000',
                    whiteSpace: 'nowrap'
                  }}>
                    Email Subject
                  </Typography>
                  <TextField
                    placeholder="Subject Line"
                    fullWidth
                    value={this.state.emailSubject}
                    onChange={this.onChangeEmailSubject}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                        '& fieldset': {
                          borderColor: '#C4C4C6',
                          borderRadius: '8px',
                        },
                        '&:hover fieldset': {
                          borderColor: '#E2E8F0',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#E2E8F0',
                        },
                      },
                      '& .MuiOutlinedInput-input': {
                        padding: '12px 16px',
                        fontSize: '14px',
                        color: '#00184C',
                        '&::placeholder': {
                          color: '#94A3B8',
                          opacity: 1,
                        },
                      },
                    }}
                  />
                </Box>

                <Box>
                  <StyledEditorContainer>
                    <ReactQuill
                      className="quillEditor"
                      value={this.state.emailContent}
                      onChange={this.onChangeEmailContent}
                      placeholder="Type your message here"
                      theme="snow"
                      modules={{
                        toolbar: [
                          ['bold', 'italic', 'underline', 'strike'],
                          [{ 'align': [] }],
                          ['blockquote', 'code-block'],
                          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        ]
                      }}
                    />
                  </StyledEditorContainer>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        }

        {/* Bottom Buttons Section */}
        <Box style={{ marginTop: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '30px', color: '#00184C' }}>
          <Button 
            sx={{ ...styles.button, borderColor: '#00184C' }} 
            variant="outlined" 
            onClick={this.cancelTemplate}
          >
            Cancel
          </Button>
          {this.state.modalStep === "Email Template Setup" && !this.state.isCreatingTemplate &&  
          <Button 
          sx={{ ...styles.button, borderColor: '#00184C' }} 
          variant="outlined" 
          onClick={this.handleSaveAsDraft}
        >
          Save as Draft
        </Button>
        }
          <Button 
            data-test-id="saveChangeBtn" 
            sx={{ ...styles.button, backgroundColor: '#00184C', color: 'white' }} 
            variant="contained" 
            onClick={this.handleSaveChanges}
          >
             {this.state.modalStep === "Email Template Setup" && this.state.isCreatingTemplate 
              && "Save Template" 
              }
              {this.state.modalStep === "Email Template Setup" && !this.state.isCreatingTemplate && "Save & Run"}
              {this.state.modalStep !== "Email Template Setup" && "Save & Next"}

          </Button>
        </Box>

        {/* Confirmation Modal - Updated dimensions */}
        <Modal
          slotProps={{
            backdrop: {
              sx: {
                backgroundColor: '#00184CB2',
                backdropFilter: 'blur(10px)',
                transition: 'all 0.5s ease',
                marginLeft: "234px"
              },
            },
          }}
          open={this.state.isConfirmModalVisible || this.isTest} 
          onClose={() => this.setState({ isConfirmModalVisible: false })}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '586px',
            height: '607px',
            bgcolor: '#EBEBEB',
            borderRadius: '16px',
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            paddingBottom: "40px"
          }}>
            {/* Close button */}
            <IconButton 
              onClick={() => this.setState({ isConfirmModalVisible: false })}
              sx={{
                position: 'absolute',
                right: '20px',
                top: '20px',
                padding: '4px'
              }}
            >
              <CloseRoundedIcon style={{ color: "#334155" }} />
            </IconButton>

            {/* Modal content */}
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              textAlign: 'center',
              flex: 1,
              marginTop: "60px"
            }}>
              <Typography sx={{
                fontSize: '30px',
                fontWeight: 700,
                color: '#0F172A',
                mb: 3,
                maxWidth: '462px'
              }}>
                Are you sure you want to cancel the changes?
              </Typography>

              <Typography sx={{
                fontSize: '18px',
                color: '#0F172A',
                maxWidth: '402px'
              }}>
                You have unsaved changes. If you leave this page, your progress will be lost.
              </Typography>
            </Box>

            {/* Buttons */}
            <Box sx={{ 
              display: 'flex', 
              gap: '16px',
              justifyContent: 'center',
              marginTop: 'auto'
            }}>
              <Button 
                onClick={() => this.setState({ isConfirmModalVisible: false })}
                sx={{
                  width: '180px',
                  height: '44px',
                  border: '1px solid #00184C',
                  borderRadius: '22px',
                  color: '#00184C',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 700
                }}
              >
                Stay on Page
              </Button>
              <Button 
                onClick={this.handleConfirmModalClose}
                sx={{
                  width: '180px',
                  height: '44px',
                  backgroundColor: '#00184C',
                  borderRadius: '22px',
                  color: 'white',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontWeight: 700,
                  '&:hover': {
                    backgroundColor: '#00184C',
                  }
                }}
              >
                Leave Page
              </Button>
            </Box>
          </Box>
        </Modal>
      </Container>}
      <img data-test-id="closeBtn" onClick={this.cancelTemplate} src={ic_back.default} style={{zIndex: 1, cursor: "pointer", width: "28px", height: "28px", position: "absolute", top: "20px", left: "15px" }} />
      <Snackbar
      open={this.state.isToastOpen || this.isTest}
      autoHideDuration={5000}
      onClose={this.handleCloseToast}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ 
        position: 'fixed',
        zIndex: 99999
      }}
    >
      <Alert
        onClose={this.handleCloseToast}
        icon={false}
        style={{
          backgroundColor: "#FFF0F0",
          color: "#000000",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderLeft: "4px solid #960505",
        }}
        action={
          <IconButton size="small" aria-label="close" onClick={this.handleCloseToast}>
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        }
      >
        {this.state.toastContent}
      </Alert>
    </Snackbar>
    </StyledBox>
  </Modal>)
  }

  DeleteConfirmationModal = () => {
    return (     <Modal
      open={this.state.isDeleteModalVisible || this.isTest}
      onClose={this.handleDeleteModalClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: '#00184CB2',
            backdropFilter: 'blur(10px)', // Blur effect
            transition: 'all 0.5s ease', // Smooth animation
            marginLeft: "234px"
          },
        },
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '580px',
        minHeight: '650px',
        bgcolor: '#EBEBEB',
        borderRadius: '16px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* Close button */}
        <IconButton 
          onClick={this.handleDeleteModalClose}
          sx={{
            position: 'absolute',
            right: '16px',
            top: '16px',
            color: '#1E293B',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <CloseRoundedIcon />
        </IconButton>

        {/* Content */}
        <Box sx={{ 
          textAlign: 'center',
          maxWidth: '460px',
          padding: '0 20px',
          margin: '120px auto 0'
        }}>
          <Typography sx={{
            fontSize: '24px',
            lineHeight: '32px',
            fontWeight: 700,
            color: '#1E293B',
            mb: 2,
            maxWidth: "385px"
          }}>
            Are you sure you want to delete the campaign?
          </Typography>

          <Typography sx={{
            fontSize: '16px',
            lineHeight: '24px',
            color: '#475569',
            maxWidth: "420px"
          }}>
            You have unsaved changes. If you leave this page, your progress will be lost.
          </Typography>
        </Box>

        {/* Buttons at bottom */}
        <Box sx={{ 
          display: 'flex',
          gap: '16px',
          justifyContent: 'center',
          marginTop: 'auto',
          marginBottom: '40px'
        }}>
          <Button
            onClick={this.handleDeleteModalClose}
            sx={{
              width: '180px',
              height: '44px',
              border: '1px solid #1E293B',
              borderRadius: '22px',
              color: '#1E293B',
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'rgba(30, 41, 59, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleConfirmDelete}
            sx={{
              width: '180px',
              height: '44px',
              backgroundColor: '#7F1D1D',
              borderRadius: '22px',
              color: '#FFFFFF',
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#991B1B'
              }
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Modal> )
  }

  DeleteConfirmationDashboardModal = () => {
    return (
    <Modal
      open={this.state.isDeleteModalDashboardVisible || this.isTest}
      onClose={this.handleDeleteModalClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: '#00184CB2',
            transition: 'all 0.5s ease', 
            marginLeft: "234px",
            backdropFilter: 'blur(10px)', 
          },
        },
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '520px',
        minHeight: '650px',
        bgcolor: '#EBEBEB',
        borderRadius: '16px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        padding: "40px 60px",
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* Close button */}
        <IconButton 
          onClick={this.handleDeleteModalClose}
          sx={{
            position: 'absolute',
            left: '16px',
            top: '16px',
            color: '#1E293B',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <img src={ic_back.default} style={{width: "24px", height: "24px"}} />
        </IconButton>

        <Typography sx={{
            fontSize: '24px',
            fontWeight: 700,
            color: 'rgba(0, 24, 76, 1)',
            lineHeight: '32px',
            marginLeft: "25px"
          }}>
            Delete Campaign
          </Typography>

        <Box sx={{ 
          textAlign: 'center',
          maxWidth: '475px',
          padding: '0 20px',
          margin: "auto"
        }}>
          <Typography sx={{
            fontSize: '30px',
            lineHeight: '42px',
            fontWeight: 700,
            color: 'rgba(51, 65, 85, 1)',
            maxWidth: "475px"
          }}>
            Are you sure you want to delete your campaign
          </Typography>
          <Typography sx={{
            fontSize: '30px',
            fontWeight: 700,
            color: 'rgba(51, 65, 85, 1)',
            lineHeight: '42px',
            maxWidth: "475px"}}>
          {this.state.campaignToDeleteDashboard?.attributes?.campaign_name}?
          </Typography>

          <Typography sx={{
            fontSize: '16px',
            lineHeight: '24px',
            color: 'rgba(51, 65, 85, 1)',
            maxWidth: "475px",
            fontWeight: 400
          }}>
            This action cannot be undone.
          </Typography>
        </Box>

        {/* Buttons at bottom */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'center',
          gap: '35px',
          marginBottom: '40px'
        }}>
          <Button
            onClick={() => this.handleDeleteModalCloseDashboard()}
            data-test-id="cancel-button-dashboard"
            sx={{
              width: '207px',
              height: '44px',
              color: '#1E293B',
              fontSize: '16px',
              fontWeight: 600,
              textTransform: 'none',
              border: '1px solid #1E293B',
              borderRadius: '22px',
              '&:hover': {
                backgroundColor: 'rgba(30, 41, 59, 0.04)'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={this.handleConfirmDelete}
            sx={{
              width: '207px',
              background: 'linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)',
              textTransform: 'none',
              border: "1px solid rgba(127, 29, 29, 1)",
              borderRadius: '22px',
              height: '44px',
              color: '#FFFFFF',
              fontSize: '16px',
              fontWeight: 600,

              '&:hover': {
                backgroundColor: '#991B1B'
              }
            }}
          >
            Delete Campaign
          </Button>
        </Box>
      </Box>
    </Modal> )
  }

  render() {
    if (this.state.isCampaignDetailsView || this.isCampaignDetailsViewTest) {
      return (
        <Layout navigation={this.props.navigation} currentRoute='Campaigns'>
          <StyledContainer>
              {/* Header Section */}
              <div data-test-id="testProcessCampaignData" style={{display: "none"}} onClick={() => this.testProcessCampaignData()}></div>
              <img 
                onClick={this.handleBackToCampaigns}
                src={ic_back.default} style={{width: "28px", height: "28px", position: "absolute", top: "30px"}} />
            
          <Box sx={{ padding: '24px 40px' }}>

            <Box sx={{paddingInline: "20px", display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '24px' }}>
             
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '4px' }}>
                  <Typography variant="h5" sx={{ 
                    fontSize: '24px',
                    fontWeight: 700,
                    color: '#00184C'
                  }}>
                    {this.state.selectedCampaign?.attributes?.campaign_name}
                  </Typography>
                  {this.state.selectedCampaign?.attributes?.status?.toUpperCase() === "RUNNING" ? <StatusChip label={this.state.selectedCampaign?.attributes?.status?.toUpperCase()} /> :<InactiveButton >INACTIVE</InactiveButton>}
                </Box>
                <Typography sx={{ color: 'rgba(0, 24, 76, 1)', fontWeight: 400, fontSize: '16px' }}>
                  Campaign start date: {moment(this.state.selectedCampaign?.attributes?.created_at).format('MM/DD/YYYY')}
                </Typography>
              </Box>

              <Box sx={{ marginLeft: 'auto', display: 'flex', gap: '16px' }}>
                  {this.state.selectedCampaign?.attributes?.status?.toUpperCase() !== "RUNNING" ? <Button
                    variant="contained"
                    onClick={() => this.updateCampaignStatus("running")}
                    startIcon={<img src={ic_play.default} style={{width: "24px", height: "24px"}} />}
                    sx={{
                      background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                      borderRadius: '26px',
                      textTransform: 'none',
                      padding: '10px 20px',
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Run Campaign
                  </Button> 
                 : <Button
                 onClick={() => this.handlePauseCampaign(this.state.selectedCampaign.id)}
                 variant="contained"
                 startIcon={<img src={ic_pause.default} style={{width: "24px", height: "24px"}} />}
                 sx={{
                   background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                   borderRadius: '26px',
                   textTransform: 'none',
                   padding: '10px 20px',
                   fontSize: "16px",
                   fontWeight: 700,
                 }}
               >
                 Pause Campaign
               </Button> 
                }
                
                <Button
                  variant="contained"
                  startIcon={<img src={ic_setting.default} style={{width: "24px", height: "24px"}} />}
                  onClick={() => this.handleEditCampaign(this.state.selectedCampaign)}
                  sx={{
                    background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                    borderRadius: '26px',
                    textTransform: 'none',
                    padding: '10px 20px',
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Edit Campaign
                </Button>

                <Button
                  variant="contained"
                  startIcon={<img src={ic_trash.default} style={{width: "24px", height: "24px"}} />}
                  onClick={() => this.handleDeleteCampaignDashboard(this.state.selectedCampaign)}
                  sx={{
                    background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                    borderRadius: '26px',
                    textTransform: 'none',
                    padding: '10px 20px',
                    fontSize: "16px",
                    fontWeight: 700,
                  }}
                >
                  Delete Campaign
                </Button>
              </Box>
            </Box>

            {/* Campaign Info Grid */}
            <Grid container spacing={1} sx={{ marginBottom: '20px', paddingInline: "20px", }}>
              <InfoGridItem label="Company:" value={this.state.selectedCampaign?.attributes?.company_name} />
              <InfoGridItem label="Revenue:" value={this.state.selectedCampaign?.attributes?.target_company_revenue} />
              <InfoGridItem label="Management:" value={this.state.selectedCampaign?.attributes?.target_company_management_level} />
              <InfoGridItem label="Location:" value={this.state.selectedCampaign?.attributes?.target_company_location} />
              <InfoGridItem label="Industry:" value={this.state.selectedCampaign?.attributes?.target_company_industry} />
              <InfoGridItem label="Email Cadence:" value={this.state.selectedCampaign?.attributes?.email_cadence} />
              <InfoGridItem label="Size:" value={this.state.selectedCampaign?.attributes?.target_company_size} />
              <InfoGridItem label="Domain Exclusion:" value={this.state.selectedCampaign?.attributes?.domain_exclusions} />
              <InfoGridItem 
                label="Email Setup:" 
                value={
                  <Link sx={{ color: '#00184C' }}>
                    {this.state.selectedCampaign?.attributes?.email_setup ?? "Subject Template"}  
                  </Link>
                } 
              />
              <InfoGridItem 
                label="Linked Bots:" 
                value={
                  <Link sx={{ color: '#00184C' }}>
                    {this.state.selectedCampaign?.attributes?.bot_name?.join(', ') || 'No bots linked'}
                  </Link>
                } 
              />
              <InfoGridItem 
                label="Title:" 
                value={this.state.selectedCampaign?.attributes?.target_company_title} 
              />
            </Grid>

            <div style={{height: "2px", backgroundColor: "rgba(114, 123, 136, 0.2)", marginBottom: "24px", width: "100%"}} />

          {this.state.campaignReports ?
            (
            <>
            <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: '20px', color: '#00184C', fontWeight: 700 }}></Typography>
                <div onClick={() => this.setState({ isDateRangeModalOpen: true })} style={{ backgroundColor: "white", borderRadius: "8px", display: 'flex', padding: "10px 15px", gap: '16px', boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)"}}>
                  <img src={ic_calendar.default} style={{width: "24px", height: "24px"}} />
                  <Typography sx={{ fontSize: '16px', color: '#00184C', fontWeight: 700 }}>
                    {`${moment(this.state.startDate).format("MM/DD/YYYY")} - ${moment(this.state.endDate).format("MM/DD/YYYY")}`}
                  </Typography>
                </div>
            </div>
            <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px' }}></Box>
            {/* Stats Cards */}
            <Box sx={{ display: 'flex', gap: '16px', marginBottom: '24px' }}>
              <StatsCard 
                title="Average Open Rate" 
                value={`${this.state.selectedCampaign?.attributes?.stats?.avg_open_rate || 0}%`}
                trend="up" 
              />
              <StatsCard 
                title="Median Open Rate" 
                value={`${this.state.selectedCampaign?.attributes?.stats?.median_open_rate || 0}%`}
                trend="down" 
              />
              <StatsCard 
                title="Average Response Rate" 
                value={`${this.state.selectedCampaign?.attributes?.stats?.avg_response_rate || 0}%`}
                trend="up" 
              />
              <StatsCard 
                title="Median Response Rate" 
                value={`${this.state.selectedCampaign?.attributes?.stats?.median_response_rate || 0}%`}
                trend="down" 
              />
              <StatsCard 
                title="Total Conversions" 
                value={this.state.selectedCampaign?.attributes?.stats?.total_conversions || 0} 
              />
            </Box>
            
            {/* Chart Container with Controls */}
            <Box 
              sx={{ 
                width: '100%', 
                display: 'flex',
                marginBottom: '24px',
              }}
            >
              {/* Chart Controls */}
              <Box sx={{ 
                width: '220px', 
                borderRadius: '8px',
                padding: '20px',
                marginRight: '16px'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                  <Typography sx={{ fontSize: '14px', color: '#00184C', fontWeight: this.state.chartViewType === 'average' ? 700 : 400 }}>
                    Average
                  </Typography>
                  <Box 
                    sx={{ 
                      width: '44px', 
                      height: '24px', 
                      backgroundColor: '#00184C', 
                      borderRadius: '12px',
                      margin: '0 8px',
                      position: 'relative',
                      cursor: 'pointer'
                    }}
                    onClick={() => this.setState({ 
                      chartViewType: this.state.chartViewType === 'average' ? 'median' : 'average' 
                    })}
                  >
                    <Box sx={{ 
                      position: 'absolute',
                      width: '18px',
                      height: '18px',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      top: '3px',
                      left: this.state.chartViewType === 'average' ? '3px' : '23px',
                      transition: 'left 0.3s'
                    }} />
                  </Box>
                  <Typography sx={{ fontSize: '14px', color: '#00184C', fontWeight: this.state.chartViewType === 'median' ? 700 : 400 }}>
                    Median
                  </Typography>
                </Box>
                
                {/* Metric Checkboxes */}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: "-10px" }}>
                    <Checkbox 
                      checked={this.state.showOpenRate} 
                      onChange={() => this.setState({ showOpenRate: !this.state.showOpenRate })}
                      sx={{
                        color: '#00184C',
                        '&.Mui-checked': {
                          color: '#00184C',
                        },
                      }}
                    />
                    <Typography sx={{ fontSize: '14px', color: '#00184C' }}>
                      Open Rate
                    </Typography>
                  </Box>
                  
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: "-10px" }}>
                    <Checkbox 
                      checked={this.state.showResponseRate} 
                      onChange={() => this.setState({ showResponseRate: !this.state.showResponseRate })}
                      sx={{
                        color: '#00184C',
                        '&.Mui-checked': {
                          color: '#00184C',
                        },
                      }}
                    />
                    <Typography sx={{ fontSize: '14px', color: '#00184C' }}>
                      Response Rate
                    </Typography>
                  </Box>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: "8px"}}>
                    <Box onClick={() => this.setState({ chartType: false })} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "8px", width: '38px', height: '38px', boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", backgroundColor: !this.state.chartType ? '#00184C' : 'white'}} >
                      <img src={this.state.chartType ? ic_line_dark.default : ic_line_white.default} style={{width: "24px", height: "24px"}} />
                    </Box>
                    <Box onClick={() => this.setState({ chartType: true })} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: "8px", marginLeft: "10px", width: '38px', height: '38px', boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", backgroundColor: this.state.chartType ? '#00184C' : 'white'}} >
                      <img src={!this.state.chartType ? ic_bar_dark.default : ic_bar_white.default} style={{width: "24px", height: "24px"}} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              
              {/* Chart Area */}
              <Box 
                sx={{ 
                  flex: 1, 
                  height: '400px', 
                  backgroundColor: '#FFFFFF',
                  borderRadius: '8px',
                  boxShadow: '0px 2px 8px 0px #00000014',
                  padding: '20px',
                  position: 'relative' // Added for absolute positioning of legend
                }}
                id="campaign-stats-chart"
              >
                {/* Custom Legend */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    display: 'flex',
                    gap: '24px',
                    zIndex: 1,
                  }}
                >
                  {this.state.showOpenRate && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: '#00184C'
                        }}
                      />
                      <Typography sx={{ color: '#00184C', fontSize: '14px', fontWeight: 700 }}>
                        Open Rate
                      </Typography>
                    </Box>
                  )}
                  {this.state.showResponseRate && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: 'rgba(228, 201, 243, 1)'
                        }}
                      />
                      <Typography sx={{ color: '#00184C', fontSize: '14px', fontWeight: 700 }}>
                        Response Rate
                      </Typography>
                    </Box>
                  )}
                </Box>

                <ResponsiveContainer width="100%" height="100%">
                  {this.state.chartType ? (
                    <BarChart data={[
                      { date: 'Mon', openRate: this.state.campaignReports?.weekday_stats?.Monday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Monday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Monday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Monday?.median_click_rate * 100 || 0 },
                      { date: 'Tue', openRate: this.state.campaignReports?.weekday_stats?.Tuesday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Tuesday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Tuesday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Tuesday?.median_click_rate * 100 || 0 },
                      { date: 'Wed', openRate: this.state.campaignReports?.weekday_stats?.Wednesday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Wednesday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Wednesday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Wednesday?.median_click_rate * 100 || 0 },
                      { date: 'Thu', openRate: this.state.campaignReports?.weekday_stats?.Thursday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Thursday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Thursday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Thursday?.median_click_rate * 100 || 0 },
                      { date: 'Fri', openRate: this.state.campaignReports?.weekday_stats?.Friday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Friday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Friday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Friday?.median_click_rate * 100 || 0 },
                      { date: 'Sat', openRate: this.state.campaignReports?.weekday_stats?.Saturday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Saturday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Saturday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Saturday?.median_click_rate * 100 || 0 },
                      { date: 'Sun', openRate: this.state.campaignReports?.weekday_stats?.Sunday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Sunday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Sunday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Sunday?.median_click_rate * 100 || 0 },
                    ]}>
                      <CartesianGrid  vertical={false} strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        tick={{ fill: '#00184C', fontWeight: 500,  fontSize: 14, }}
                        dy={15} // Add top margin to date labels
                        padding={{ left: 30, right: 30 }} // Add padding to the sides
                        dataKey="date" 
                        axisLine={false}
                        tickLine={false}
                      />
                      <YAxis 
                        tick={{fontSize: 14, fontWeight: 500, fill: '#00184C' }}
                        domain={[0, 100]}
                        tickFormatter={(value) => `${value}%`}
                        axisLine={false}
                        tickLine={false}
                      />
                      <RechartsTooltip 
                        contentStyle={{
                          padding: '8px 12px',
                          color: '#00184C',
                          fontSize: '14px',
                          fontWeight: 500,
                          backgroundColor: '#FFFFFF',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                        }}
                        formatter={(value: number) => [`${value.toFixed(1)}%`]}
                        labelStyle={{ color: '#00184C', fontWeight: 600 }}
                      />
                      {this.state.showOpenRate && (
                        <Bar
                          dataKey={this.state.chartViewType === 'average' ? "openRate" : "medianOpen"}
                          radius={[4, 4, 0, 0]}
                          fill="#00184C"
                          name="Open Rate"
                        />
                      )}
                      {this.state.showResponseRate && (
                        <Bar
                          dataKey={this.state.chartViewType === 'average' ? "responseRate" : "medianResponse"}
                          radius={[4, 4, 0, 0]}
                          fill="rgba(228, 201, 243, 1)"
                          name="Response Rate"
                        />
                      )}
                    </BarChart>
                  ) : (
                    <LineChart data={[
                      { date: 'Mon', openRate: this.state.campaignReports?.weekday_stats?.Monday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Monday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Monday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Monday?.median_click_rate * 100 || 0 },
                      { date: 'Tue', openRate: this.state.campaignReports?.weekday_stats?.Tuesday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Tuesday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Tuesday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Tuesday?.median_click_rate * 100 || 0 },
                      { date: 'Wed', openRate: this.state.campaignReports?.weekday_stats?.Wednesday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Wednesday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Wednesday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Wednesday?.median_click_rate * 100 || 0 },
                      { date: 'Thu', openRate: this.state.campaignReports?.weekday_stats?.Thursday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Thursday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Thursday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Thursday?.median_click_rate * 100 || 0 },
                      { date: 'Fri', openRate: this.state.campaignReports?.weekday_stats?.Friday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Friday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Friday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Friday?.median_click_rate * 100 || 0 },
                      { date: 'Sat', openRate: this.state.campaignReports?.weekday_stats?.Saturday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Saturday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Saturday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Saturday?.median_click_rate * 100 || 0 },
                      { date: 'Sun', openRate: this.state.campaignReports?.weekday_stats?.Sunday?.avg_open_rate * 100 || 0, responseRate: this.state.campaignReports?.weekday_stats?.Sunday?.avg_click_rate * 100 || 0, medianOpen: this.state.campaignReports?.weekday_stats?.Sunday?.median_open_rate * 100 || 0, medianResponse: this.state.campaignReports?.weekday_stats?.Sunday?.median_click_rate * 100 || 0 },
                    ]}>
                      <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#E2E8F0" />
                      <XAxis 
                        dataKey="date" 
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: '#00184C', fontSize: 14, fontWeight: 500 }}
                        dy={15} // Add top margin to date labels
                        padding={{ left: 30, right: 30 }} // Add padding to the sides
                      />
                      <YAxis 
                        axisLine={false}
                        tickLine={false}
                        tick={{ fill: '#00184C', fontSize: 14, fontWeight: 500 }}
                        domain={[0, 100]}
                        tickFormatter={(value) => `${value}%`}
                      />
                      <RechartsTooltip 
                        contentStyle={{
                          backgroundColor: '#FFFFFF',
                          border: 'none',
                          borderRadius: '8px',
                          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                          padding: '8px 12px',
                          color: '#00184C',
                          fontSize: '14px',
                          fontWeight: 500
                        }}
                        formatter={(value: number) => [`${value.toFixed(1)}%`]}
                        labelStyle={{ color: '#00184C', fontWeight: 600 }}
                      />
                      {this.state.showOpenRate && (
                        <Line
                          type="monotone"
                          dataKey={this.state.chartViewType === 'average' ? "openRate" : "medianOpen"}
                          stroke="#00184C"
                          strokeWidth={2}
                          dot={{ 
                            r: 4,
                            fill: '#00184C', 
                            strokeWidth: 0 
                          }}
                          activeDot={{
                            r: 6,
                            fill: '#00184C',
                            strokeWidth: 0
                          }}
                          name="Open Rate"
                        />
                      )}
                      {this.state.showResponseRate && (
                        <Line
                          type="monotone"
                          dataKey={this.state.chartViewType === 'average' ? "responseRate" : "medianResponse"}
                          stroke="rgba(228, 201, 243, 1)"
                          strokeWidth={2}
                          dot={{ 
                            r: 4,
                            fill: 'rgba(228, 201, 243, 1)', 
                            strokeWidth: 0 
                          }}
                          activeDot={{
                            r: 6,
                            fill: 'rgba(228, 201, 243, 1)',
                            strokeWidth: 0
                          }}
                          name="Response Rate"
                        />
                      )}
                    </LineChart>
                  )}
                </ResponsiveContainer>
              </Box>
            </Box>
            </>
            ) : 
            <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
             <div style={{marginTop: "8%", display: "flex", flexDirection: "column", maxWidth: "610px"}}>
              <Typography sx={{ fontSize: '48px', color: 'rgba(0, 24, 76, 1)', fontWeight: 700 }}>No available data</Typography>
              <Typography sx={{ fontSize: '24px', color: 'rgba(51, 65, 85, 1)', fontWeight: 400 }}>Your campaign needs time to collect data. Check back
              here once your campaign has had some time to run.</Typography>
              <Button
                onClick={this.handleBackToCampaigns}
                sx={{
                    width: '207px',
                    height: '44px',
                    background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                    borderRadius: '22px',
                    color: 'white',
                    border: "1px solid rgba(164, 223, 255, 1)",
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 700,
                    mt: 2
                  }}
                >
                  Back
                </Button>
                </div>
            </div>
              }

              
          </Box>


          {/* Date Range Modal */}
          <Modal
            open={this.state.isDateRangeModalOpen || this.isTest}
            onClose={() => this.onCloseDateRangeModal()}
            slotProps={{
              backdrop: {
                sx: {
                  backgroundColor: '#00184CB2',
                  backdropFilter: 'blur(10px)',
                  transition: 'all 0.5s ease',
                  marginLeft: "234px"
                },
              },
            }}
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '660px',
              bgcolor: '#EBEBEB',
              borderRadius: '16px',
              p: 5,
              paddingInline: '60px',
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton 
                  onClick={() => this.setState({ isDateRangeModalOpen: false })}
                  sx={{ mr: 2, position: 'absolute', left: '10px', top: '10px' }}
                >
                  <img src={ic_back.default} style={{width: "22px", height: "22px"}} />
                </IconButton>
                <Typography variant="h5" sx={{ 
                  fontSize: '24px',
                  fontWeight: 700,
                  color: 'rgba(51, 65, 85, 1)'
                }}>
                  Select Date Range
                </Typography>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={7}>
                  <Box sx={{ bgcolor: 'white', borderRadius: 2 }}>
                    <div style={{ display: "flex", gap: "10px", flexDirection: "column", padding: "25px", paddingInline: "35px", justifyContent: "space-between"}}>
                      <Typography sx={{fontSize: "14px", fontWeight: 500, color: "rgba(100, 116, 139, 1)"}}>
                        Select date
                      </Typography>
                      <Typography sx={{fontSize: "26px", fontWeight: 400, color: "rgba(29, 27, 32, 1)"}}>
                        {`${moment(this.state.startDate).format("MMM D")} - ${moment(this.state.endDate).format("MMM D")}`}
                      </Typography>
                    </div>
                    <div style={{width: "100%", height: "1px", backgroundColor: "rgba(0, 47, 105, 1)", marginBottom: "10px"}}></div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateCalendar
      value={this.state.startDate}
      onChange={this.handleDateSelect}
      slotProps={{
        day: this.renderDay
      }}
      sx={{
        '& .MuiDayCalendar-slideTransition': {
          minHeight: '260px'
        },
        width: '100%',
        height: '100%',
        maxHeight: "300px",
        '& .MuiDayCalendar-header': {
          display: "none"
        },
        '& .MuiPickersCalendarHeader-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 25px',
          marginTop: '8px',
          '& .MuiPickersCalendarHeader-label': {
            color: 'rgba(100, 116, 139, 1)',
            fontSize: '14px',
            fontWeight: 600,
          },
          '& .MuiIconButton-root': {
            color: 'rgba(100, 116, 139, 1)',
          }, 
        },
        '& .MuiDayCalendar-weekContainer': {
        },
        '& .MuiPickersDay-root': {
          margin: '0px',
          width: '40px',
          height: '40px',
          fontSize: '14px',
          fontWeight: 400,
          color: "rgba(0, 24, 76, 1)"
        }
      }}
    />
                    </LocalizationProvider>
                  </Box>
                </Grid>
                
                <Grid item xs={5}>
                  <Typography sx={{ mb: 1, color: 'rgba(51, 65, 85, 1)', fontWeight: 700, fontSize: '14px' }}>Select Date Range</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '14px',
                      cursor: 'pointer',
                      backgroundColor: 'white',
                      border: '1px solid #CBD5E1',
                      borderRadius: this.state.isDateRangeOpen ? '8px 8px 0 0' : '8px',
                    }}
                    onClick={() => this.handleDateRangePreset()}
                  >
                    <Typography style={{
                      color: this.state.dateRangePreset ? '#334155' : '#CBD5E1',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}>
                      {this.state.dateRangePreset === 'past7days' ? 'Past 7 Days' :
                       this.state.dateRangePreset === 'past30days' ? 'Past 30 Days' :
                       this.state.dateRangePreset === 'custom' ? 'Custom Range' : 'Select range'}
                    </Typography>
                    <img
                      src={icThinDropdown}
                      style={{
                        transform: this.state.isDateRangeOpen ? "rotate(0deg)" : "rotate(180deg)",
                        width: "16px",
                        height: "16px"
                      }}
                    />
                  </Box>
                  {this.state.isDateRangeOpen && (
                    <Paper style={{
                      marginTop: '-2px',
                      border: '1px solid #CBD5E1',
                      borderTop: 'none',
                      borderRadius: '0 0 8px 8px',
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }}>
                      {[
                        { value: 'past7days', label: 'Past 7 Days' },
                        { value: 'past30days', label: 'Past 30 Days' },
                        { value: 'custom', label: 'Custom Range' }
                      ].map((option) => (
                        <Box
                          key={option.value}
                          sx={{
                            padding: '10px 14px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '&:hover': {
                              backgroundColor: '#F8FAFC'
                            }
                          }}
                          onClick={() => {
                            this.setState({ 
                              dateRangePreset: option.value,
                              isDateRangeOpen: false 
                            });
                          }}
                        >
                          <Typography style={{
                            color: '#334155',
                            fontSize: '14px',
                            fontWeight: 700
                          }}>
                            {option.label}
                          </Typography>
                          <Checkbox
                            checked={this.state.dateRangePreset === option.value}
                            onChange={() => this.onHandleCheckbox(option)}
                            icon={
                              <span
                                style={{
                                  boxSizing: "border-box",
                                  width: 16,
                                  height: 16,
                                  borderRadius: 4,
                                  border: '1px solid #64748B',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: 'white',
                                }}
                              />
                            }
                            checkedIcon={
                              <span
                                style={{
                                  width: 16,
                                  height: 16,
                                  borderRadius: 4,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#00184C',
                                  boxSizing: "border-box",
                                }}
                              >
                                <CheckIcon style={{ color: 'white', fontSize: 16 }} />
                              </span>
                            }
                          />
                        </Box>
                      ))}
                    </Paper>
                  )}

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px'}}>
                    <div style={{width: '100%', height: '1px', backgroundColor: ' rgba(0, 0, 0, 0.5)'}} />
                    <Typography sx={{ 
                      textAlign: 'center', 
                      my: 2,
                      position: 'relative',
                      fontSize: '12px',
                      fontWeight: 700,
                      color: 'rgba(51, 65, 85, 1)',
                    }}>
                      OR
                    </Typography>
                    <div style={{width: '100%', height: '1px', backgroundColor: ' rgba(0, 0, 0, 0.5)'}} />
                  </div>

                  <Typography sx={{ mb: 1, fontWeight: 700, color: "rgba(51, 65, 85, 1)", fontSize: '14px' }}>Start</Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: 'white',
                      border: '1px solid #CBD5E1',
                      borderRadius: '8px',
                      mb: 2,
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        value={this.state.startDate}
                        onChange={(newValue) => this.setState({ startDate: newValue ?? "", dateRangePreset: "custom" })}
                        sx={{
                          width: '100%',
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            sx: {
                              '& .MuiInputBase-root': {
                                backgroundColor: 'transparent',
                                border: 'none',
                                '& .MuiInputBase-input': {
                                  color: '#334155',
                                  padding: '14px',
                                  fontSize: '14px',
                                  fontWeight: 700,
                                },
                              },

                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            },
                            placeholder: "Select date",
                            InputProps: {
                              endAdornment: (
                                <img
                                  src={icThinDropdown}
                                  style={{
                                    width: "16px",
                                    transform: "rotate(180deg)",
                                    height: "16px",
                                  }}
                                />
                              ),
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Typography sx={{ mb: 1, fontWeight: 700, color: "rgba(51, 65, 85, 1)", fontSize: '14px' }}>End</Typography>
                  
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #CBD5E1',
                      borderRadius: '8px',
                      justifyContent: 'space-between',
                      backgroundColor: 'white',
                    }}
                  >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>

                      <MobileDatePicker
                        value={this.state.endDate}
                        onChange={(newValue) => this.setState({ endDate: newValue ?? "", dateRangePreset: "custom" })}
                        sx={{
                          width: '100%',
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            sx: {
                              '& .MuiInputBase-root': {
                                border: 'none',
                                backgroundColor: 'transparent',
                                '& .MuiInputBase-input': {
                                  color: '#334155',
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  padding: '14px',
                                },
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            },
                            placeholder: "Select date",
                            InputProps: {
                              endAdornment: (
                                <img
                                  src={icThinDropdown}
                                  style={{
                                    transform: "rotate(180deg)",
                                    width: "16px",
                                    height: "16px",
                                  }}
                                />
                              ),
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                gap: 2, 
                mt: 4
              }}>
                <Button
                  onClick={() => this.setState({ isDateRangeModalOpen: false })}
                  sx={{
                    width: '180px',
                    height: '44px',
                    border: '1px solid #00184C',
                    borderRadius: '22px',
                    color: '#00184C',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleApplyDateRange}
                  sx={{
                    width: '180px',
                    height: '44px',
                    background: 'linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)',
                    borderRadius: '22px',
                    color: 'white',
                    border: "1px solid rgba(164, 223, 255, 1)",
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 700,
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Modal>
        </StyledContainer>
        <this.CampaignModal />
        <this.DeleteConfirmationModal />
        <this.DeleteConfirmationDashboardModal />
        </Layout>
      );
    }

    // Original campaign listing view
    return (
      <Layout navigation={this.props.navigation} currentRoute='Campaigns'>
      <StyledContainer>
        {/* Header Section */}
        <div style={{display: "none"}}></div>
        <Box className="header-container">
          <img src={ic_back.default} style={{width: "28px", height: "28px", position: "absolute", top: "30px"}} />
          {/* Title and Description */}
          <Box style={{display: "flex", flexDirection: "column", marginLeft: "80px"}}>
          <Typography variant="h5" fontWeight="bold" style={{
            color: "#00184C",
            fontSize: "24px",
            
          }}>
            Welcome back, {this.state.profile?.attributes?.first_name}!
          </Typography>
          <Typography style={{
            color: "#00184C",
            fontSize: "16px",
            fontWeight: "400"
          }} variant="body2" color="textSecondary">
            Take a look at your organization!
          </Typography>
          </Box>
          {/* Actions (Add User, Filters, Search) */}
          <Box className="header-top">
            <Button
              style={{
                background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
                color: "#FFFFFF",
                borderRadius: "26px",
                padding: "10px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
              variant="contained"
              color="primary"
              onClick={this.handleOpenModal}
              className="tour-get-started-item"
            >
              <img src={ic_plus.default} style={{width: "24px", height: "24px"}} />
              New Campaign
            </Button>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #E2E8F0',
                  borderRadius: '0px',
                  padding: '4px 8px',
                  width: '250px',
                  backgroundColor: "white",
                  height: "38px",
                  boxSizing: "border-box",
                  borderBottom: "1px solid #C4C4C6",
                }}
              >
              <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
               <TextField
                  style={{height: "100%"}}
                  placeholder="Search"
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: '14px',
                       height: "100%",   
                      fontFamily: "Inter, sans-serif",
                      color: '#00184C', 
                      fontWeight: 700,
                  },
                  }}
                  value={this.state.searchText}
                  onChange={this.onChangeSearch}
                  fullWidth
                />
              </Box>
          </Box>
        </Box>
  <div style={{ display: "flex", flexDirection: "row", gap: "35px", marginLeft: "80px", marginBottom: "15px" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                       <StyledHtmlTooltip  
                            placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Available Campaigns</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                    Available campaigns represent the remaining number of campaigns you may create out of the total number of campaigns your organization has access to. 
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                            </StyledHtmlTooltip>
                  <Typography style={{ fontSize: "18px", fontWeight: 700, color: "#00184C" }}>Available Campaigns :</Typography>
                  <Typography style={{ fontSize: "18px", fontWeight: 400, color: "#00184C" }}>{this.getCampaignStats().activeCampaignCount} out of {this.getCampaignStats().totalCampaigns}</Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"  }}>
                   <StyledHtmlTooltip  
                            placement="bottom-start"
                              title={
                                <React.Fragment>
                                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "5px"}}>
                                      <img src={infoIcon} alt="info icon" />
                                      <Typography color="inherit" style={{fontWeight: 700}}>Active Campaigns</Typography>
                                  </div>
                                  <Typography color="inherit" style={{lineHeight: "22px", fontSize: "14px"}}>
                                    Active campaigns represent the number of campaigns that have at least 1 assigned bot and are actively running. 
                                  </Typography>
                                </React.Fragment>
                              }
                            >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '36px', height: '36px', boxShadow: "0px 2px 8px 0px #00000014", borderRadius: '50%', backgroundColor: '#F1F1F3' }}>
                              <img src={infoIcon} alt="info icon" />
                            </div>
                    </StyledHtmlTooltip>
                  <Typography style={{ fontSize: "18px", fontWeight: 700, color: "#00184C" }}>Active Campaigns :</Typography>
                  <Typography style={{ fontSize: "18px", fontWeight: 400, color: "#00184C" }}>{this.getCampaignStats().activeCampaignCount}</Typography>
                </div>
              </div>

        {/* Table Section */}
        <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{overflow:"hidden"}}>
              <StyledTableCell>Campaign</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Bot</StyledTableCell>
              <StyledTableCell style={{textAlign: "center"}}>Applied on</StyledTableCell>
              <StyledTableCell style={{opacity: 0, borderRadius: 0}}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.groupList.slice( (this.state.currentPage - 1) * 12, (this.state.currentPage - 1) * 12 + 12).map((group: any) => (
              <TableRow key={group.id} hover>
                <StyledTableCell style={{paddingLeft: "36px", textDecoration: "underline", cursor: "pointer"}} onClick={() => this.handleCampaignClick(group)}>{`${group.attributes.campaign_name}`}</StyledTableCell>
                <StyledTableCell>
                  {group.attributes.status === "running" && !group.attributes.is_draft && <StatusButton>
                    {group.attributes.status}
                  </StatusButton>}
                  {group.attributes.status === "paused" && !group.attributes.is_draft &&  <PausedButton>
                    {group.attributes.status}
                  </PausedButton>}
                  {group.attributes.status === "completed" && !group.attributes.is_draft && <CompletedButton>
                    {group.attributes.status}
                  </CompletedButton>}
{group.attributes.is_draft && <DraftButton>
                    Draft
                  </DraftButton>}
{group.attributes.status === "inactive" && !group.attributes.is_draft && <InactiveButton>
                    {group.attributes.status}
                  </InactiveButton>}
                </StyledTableCell>
                <StyledTableCell>{group.attributes.bot_name.join(",")}</StyledTableCell>
                <StyledTableCell style={{
                    alignItems: "center",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "36px"
                }}>
                {moment(group.attributes.created_at).format("MM/DD/YYYY")}
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{display: "flex", gap: "15px", justifyContent: "center"}}>
                    <EditButton 
                      data-test-id="details-btn"
                      style={{ color:"white", backgroundColor: "#00184C"}}
                      onClick={() => this.handleCampaignClick(group)}
                    >
                      Details
                    </EditButton>
                    {group.attributes.status === "running" && !group.attributes.is_draft ? (
                      <EditButton 
                        style={{ color:"#00184C", backgroundColor: "#E4C9F3", border: "1px solid #E4C9F3" }}
                        onClick={() => this.handlePauseCampaign(group.id)}
                      >
                        Pause
                      </EditButton>
                    ) : (group.attributes.is_draft || group.attributes.status !== "running") && (
                      <EditButton 
                        style={{ color:"#FECACA", backgroundColor: "#DC2626", border: "1px solid #DC2626" }}
                        onClick={() => this.handleDeleteCampaign(group.id)}
                      >
                        Delete
                      </EditButton>
                    )}
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
      </StyledContainer>
      <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{ marginTop: 2, height: "100%", marginBottom: "40px" }}
    >
      <div style={{width: "120px", height: "1px"}} />
      {/* Pagination */}
      <StyledPagination
        count={Math.ceil(this.state.groupList.length / 12)}
        page={this.state.currentPage}
        shape="rounded"
        siblingCount={0} 
        boundaryCount={1} 
        onChange={(event, page) => this.setState({ currentPage: page })}
      />

      {/* Results Summary */}
      <ResultsSummary>
        {this.getResultsSummary()}
      </ResultsSummary>
    </Stack>   
    <this.CampaignModal />
    <this.DeleteConfirmationModal />
    </Layout>
    );
  }
}

const StyledSlider = muiStyled(Slider)(({ theme }) => ({
  color: 'transparent',
  height: 6,
  padding: '30px 0',
  marginLeft: '20px',
  width: 'calc(100% - 20px)',
  '& .MuiSlider-track': {
    border: 'none',
    background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
    height: 4,
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#94A3B8',
    height: 3,
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    height: 12,
    width: 12,
    borderRadius: '50%',
    backgroundColor: '#94A3B8',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  '& .MuiSlider-markActive': {
    height: 12,
    width: 12,
    borderRadius: '50%',
    background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
  },
  '& .MuiSlider-thumb': {
    background: '#F6D8FF',
    height: 28,
    width: 28,
    border: '2px solid #00184C',
    borderRadius: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    opacity: 1,
  },
  '& .MuiSlider-markLabel': {
    color: "#334155",
    fontWeight: 700,
    fontSize: "14px",
    marginTop: "20px",
    maxWidth: "160px",
    whiteSpace: "break-spaces",
    textAlign: "center",
    '&[data-index="0"]': {
      transform: 'translateX(-20px)',
    },
  },
}));

const StyledBox = muiStyled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 'calc(50% + 234px / 2)', // Adjusting for the sidebar width
  transform: 'translate(-50%, -50%)',
  width: "calc(90% - 235px)",
  boxShadow: theme.shadows[5],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  background: "linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(235, 235, 235, 0.9) 100%)",
  border: "3px solid #FFFFFFE5",
  borderRadius: "16px",
  boxSizing: "border-box",
  height: "90vh",
  padding: "10px",

  "@media (max-height: 720px)": {
    minHeight: "unset",
    scale: 0.85,
    marginTop: "-50px",
    marginLeft: "-32px",
    padding: "20px",
  },

  "& .cancel-btn": {
    border: "1px solid #00184C",
    flex: 1,
    display: "flex",
    textTransform: "capitalize",
    color: "#00184C",
    borderRadius: "24px",
    fontWeight: 700,
    height: "44px",
    maxWidth: "210px"
  },

  "& .create-btn": {
    display: "flex",
    flex: 1,
    borderRadius: "24px",
    border: "1px solid rgba(228, 201, 243, 1)",
    background: " #00184C",
    textTransform: "capitalize",
    color: "white",
    fontWeight: 700,
    height: "44px",
    maxWidth: "210px"
  },

  "& .update-btn": {
    border: "1px solid rgba(228, 201, 243, 1)",
    background: "linear-gradient(122.97deg, rgba(228, 201, 243, 1) -55.47%, #00184C 40.61%)",
    display: "flex",
    flex: 1,
    textTransform: "capitalize",
    color: "white",
    borderRadius: "24px",
    height: "44px",
    maxWidth: "210px",
    fontWeight: 700,
  },

  "& .delete-btn": {
    border: "1px solid #7F1D1D",
    display: "flex",
    color: "white",
    height: "44px",
    background: "linear-gradient(122.97deg, #FFA0A0 -55.47%, #4C0000 40.61%)",
    flex: 1,
    borderRadius: "24px",
    textTransform: "capitalize",
    fontWeight: 700,
    maxWidth: "210px"
  },

  "& .textField": {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px",
    marginTop: "10px",

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },

  "& .textArea": {
    border: "1px solid #CBD5E1",
    backgroundColor: "white",
    borderRadius: "8px",
    marginTop: "10px",
    width: "100%",
    minHeight: "156px",

    "&::placeholder": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "left",
      color: "#64748B"
    },

    "@media (max-height: 720px)": {
      "& input": { padding: "12px 14px" }
    },
  },
}));

const styles = {
  labelStyle: {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 700 as 700,
    paddingBottom: '4px',
    lineHeight: '22px',
    marginTop: "15px",
    paddingHorizontal: "20px"
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column" as "column"
  },
  textInput: {
    border: '1px solid #CBD5E1 !important',
    backgroundColor: "white",
    borderRadius: "8px",
  },
  button: {
    height: '44px',
    width: '207px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    color: '#00184C',
    lineHeight: '22px',
    textTransform: "capitalize",
  }
}

const ResultsSummary = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    justifyContent: "center", // Align pagination to the left
  },
  "& .MuiPaginationItem-root": {
    color:"#00184C",
    fontWeight: 400,
    "&.Mui-selected": {
      color:"#00184C",
      borderRadius: "50%",
      backgroundColor: "transparent",
      fontWeight: 700,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "white",
  color: "#22223E",
  textAlign: "start",
  borderBottom: "none",
  borderTop: "1px solid #E9E9EA",
  left: "20px"
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "*": { fontFamily: "Inter, sans-serif" },

  "& *": {
    boxSizing: "border-box",
    fontFamily: "Inter, sans-serif"
  },
  '.header-container': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    backgroundColor: "#E9E9EA",
    marginTop: "20px",
  },
  '.header-top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "20px",
    flexDirection: "row",
    gap: "20px"
  },
  '.header-actions': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '.table-container': {
    maxHeight: 600,
    overflow: 'hidden',
  },
  '.filter-button': {
    padding: theme.spacing(1),
    width: "96px",
    height: "38px",
    borderBottom: "1px solid #C4C4C6",
    backgroundColor: "white",
    borderRadius: 0, 
    gap: "10px"
  },
}));

const StatusButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D1FAE5",
  color:"#059669" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
}));

const PausedButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEF3C7",
  color:"#D97706" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
}));

const CompletedButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEE2E2",
  color:"#DC2626" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
}));

const DraftButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#93C5FD",
  color:"#1E3A8A" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
}));

const InactiveButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D6D3D1",
  color:"#57534E" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
}));

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#fff",
  color: "#00184C",
  border: "1px solid #00184C",
  borderRadius: "8px",
  padding: "4px 8px",
  fontWeight: "bold",
  width: "75px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledHtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white, // LightTooltip background
    color: '#334155', // LightTooltip text color
    boxShadow: "0px 25px 50px 0px #00000017",
    fontSize: "14px", // LightTooltip font-size
    borderRadius: "0px 16px 16px 16px",
    width: "400px",
    padding: "15px",
    marginTop: "-15px !important"
  },
}));

const StyledEditorContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none !important",
  boxShadow: "none !important",
  "*": { fontFamily: "Inter, sans-serif" },

  "& .ql-toolbar": {
    backgroundColor: "white",
    borderColor: "#E2E8F0 !important",
    borderWidth: "1px 1px 0px 1px !important",
    borderStyle: "solid !important",
    borderRadius: "8px 8px 0 0",
    padding: "8px 12px",
  },

  "& .ql-container": {
    backgroundColor: "white",
    borderColor: "#E2E8F0 !important",
    borderWidth: "0 1px 1px 1px !important",
    borderStyle: "solid !important",
    borderRadius: "0 0 8px 8px",
    fontSize: "14px",
    fontFamily: "inherit !important",
    maxHeight: "220px",
  },

  "& .ql-editor": {
    minHeight: "200px",
    fontSize: "14px",
    color: "#64748B",
    fontFamily: "inherit !important",
    "&.ql-blank::before": {
      color: "#64748B",
      fontStyle: "normal",
      fontSize: "14px",
      fontFamily: "inherit !important",
    }
  },

  "& .ql-formats": {
    marginRight: "12px !important",
  },

  "& .ql-stroke": {
    stroke: "#0F172A !important",
  },

  "& .ql-fill": {
    fill: "#0F172A !important",
  },

  "& .ql-picker": {
    color: "#0F172A !important",
  },

  "& .ql-picker-options": {
    backgroundColor: "white !important",
    border: "1px solid #E2E8F0 !important",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  },

  "& .ql-active": {
    "& .ql-stroke": {
      stroke: "#0F172A !important",
    },
    "& .ql-fill": {
      fill: "#0F172A !important",
    },
    color: "#0F172A !important",
  },
}));

const QuillEditor = ({value, onChange, placeholder}: any) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      [{ color: [] }],
      ["bold", "italic", "underline", "strike"],
      ["code", "blockquote"],
      ["link", "image", "code-block"],
      ["clean"],
    ],
  };

  return (
    <StyledEditorContainer>
      <ReactQuill
        className="quillEditor"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        theme="snow"
        modules={modules}
      />
    </StyledEditorContainer>
  );
};

const InfoGridItem = ({ label, value }: { label: string, value: any }) => (
  <Grid item xs={4}>
    <Box sx={{ display: 'flex', gap: '8px' }}>
      <Typography sx={{ color: '#00184C', fontWeight: 600, fontSize: "16px" }}>{label}</Typography>
      <Typography sx={{ color: '#00184C', fontSize: "16px" }}>{value}</Typography>
    </Box>
  </Grid>
);

const StatsCard = ({ title, value, trend }: { title: string, value: string, trend?: 'up' | 'down' }) => (
  <Paper sx={{ 
    flex: 1, 
    p: 3, 
    borderRadius: '8px',
    boxShadow:" 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    background: 'white',
    position: "relative",
    gap: "10px"
  }}>
    <Typography sx={{ color: 'rgba(0, 24, 76, 1)', marginBottom: '8px', fontSize: "16px", fontWeight: 600 }}>{title}</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Typography variant="h4" sx={{ color: '#00184C', fontWeight: 700, fontSize: "36px" }}>{value}</Typography>
    </Box>
    {trend === "up" && <img src={ic_trend_up.default} style={{width: "92px", height: "76px", position: "absolute", right:"0px", bottom:"0px"}} />}
    {trend === "down" && <img src={ic_trend_down.default} style={{width: "92px", height: "76px", position: "absolute", right:"0px", bottom:"0px"}} />}
  </Paper>
);

const StatusChip = styled(({ label, ...props }: any) => (
  <Box {...props}>
    {label}
  </Box>
))({
  backgroundColor: '#D1FAE5',
  color: '#059669',
  padding: '4px 12px',
  borderRadius: '16px',
  fontSize: '12px',
  fontWeight: 600,
  textTransform: 'uppercase'
});