// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Contactus from "../../blocks/contactus/src/Contactus";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import UserProfileBasicBlockApp from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Analytics from "../../blocks/analytics/src/Analytics";
import Integratewithdataaggregator from "../../blocks/integratewithdataaggregator/src/Integratewithdataaggregator";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Cf3rdpartyemailproviderapiintegration0 from "../../blocks/cf3rdpartyemailproviderapiintegration0/src/Cf3rdpartyemailproviderapiintegration0";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre.web";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Notifications from "../../blocks/notifications/src/Notifications";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Chatgptpromptdesign from "../../blocks/chatgptpromptdesign/src/Chatgptpromptdesign";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web";
import EmailListDataListing from "../../blocks/emaillists/src/EmailListDataListing.web";
  import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import { TourProvider, withTour } from '@reactour/tour';
import { Box, Button, Typography } from '@mui/material';
import { getStorageData } from '../../framework/src/Utilities';
import { Message } from '../../framework/src/Message';
import HelpCentreDetails from "../../blocks/helpcentre/src/HelpCentreDetails.web";

const routeMap = {
HelpCentreDetails: {
  component: HelpCentreDetails,
  path: '/HelpCentreDetails/:id',
},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Campaigns:{
 component: EmailListDataListing,
path:"/Campaigns"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/Profile"},
UserProfileBasicBlockApp:{
  component:UserProfileBasicBlockApp,
 path:"/UserProfileBasicBlockApp"},
ProfileForgotPassword:{
  component: UserProfileBasicBlock,
 path:"/ResetPassword"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Integratewithdataaggregator:{
 component:Integratewithdataaggregator,
path:"/Integratewithdataaggregator"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock",
exact: true,
},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
Cf3rdpartyemailproviderapiintegration0:{
 component:Cf3rdpartyemailproviderapiintegration0,
path:"/Cf3rdpartyemailproviderapiintegration0"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
HelpCentre:{
 component: withTour(HelpCentre),
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Chatgptpromptdesign:{
 component:Chatgptpromptdesign,
path:"/Chatgptpromptdesign"},
Bots:{
  component: CustomisableUserProfiles,
 path:"/Bots"},
  Home: {
    component: withTour(Dashboard),
    path: '/Home',
  },

  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
    exact: true,
  },
  LandingPageRoot: {
    component: LandingPage,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  const navigate = useNavigate();
    const onboardingPopup = ({title, description, currentStepNumber, onNext,  onPrevious, ...props}) => {
        return (
          <Box sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography 
            variant="h6" 
            style={{ 
              fontSize: "20px", 
              fontWeight: 600, 
              marginBottom: "12px",
              color: "#00184C"
            }}
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              color: "rgba(51, 65, 85, 1)",
              marginBottom: "16px",
              fontWeight: 400
            }}
          >
            {description}
          </Typography>
         { currentStepNumber !== 11 && <Box sx={{
            width: "100%",
            height: "4px",
            backgroundColor: "#E2E8F0",
            borderRadius: "2px",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              top: 0,
              height: "100%",
              width: `${currentStepNumber * 10}%`,
              backgroundColor: "#00184C",
              borderRadius: "2px"
            }
          }} />}
           { currentStepNumber < 11 && <Typography style={{ 
              color: "rgba(0, 24, 76, 1)", 
              fontSize: "10px",
              fontWeight: 700,
              alignSelf: "flex-end"
            }}>
              {currentStepNumber} of 10
            </Typography>}
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "flex-end"
          }}>
            <Box sx={{ display: "flex", gap: "12px", marginTop: "12px" }}>
              {currentStepNumber > 1 && currentStepNumber !== 11 && <Button
                onClick={() => {
                  if (onPrevious) {
                    onPrevious(props);
                  } else {
                    props.setCurrentStep((s) => s - 1)
                  }
                }}
                style={{
                  backgroundColor: "white",
                  color: "#00184C",
                  borderRadius: '16px 16px 16px 16px',
                  padding: "3px 12px",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  minWidth: "100px",
                  border: "1px solid #00184C"
                }}
              >
                Previous
              </Button>}
              {currentStepNumber == 11 && <Button
                onClick={() => {
                  if (window.location.pathname !== "/Home") {
                    navigate("/Home");
                  }
                  setTimeout(() => {
                    props.setCurrentStep(0);
                  }, 1000);
                }}
                style={{
                  backgroundColor: "white",
                  color: "#00184C",
                  borderRadius: '24px',
                  padding: "3px 12px",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  minWidth: "100px",
                  border: "1px solid #00184C"
                }}
              >
                Restart Guide
              </Button>}
              <Button
                onClick={() => {
                  if (onNext) {
                    onNext(props);
                  } else {
                    props.setCurrentStep((s) => s + 1);
                  }
                }}
                style={{
                  backgroundColor: "white",
                  color: "rgba(0, 24, 76, 1)",
                  border: "1px solid rgba(0, 24, 76, 1)",
                  borderRadius: "20px",
                  padding: "3px 12px",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "14px",
                  minWidth: "100px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)"
                }}
              >
                {currentStepNumber < 11 ? currentStepNumber === 10 ? "Finish" : "Next" : "Close"}
              </Button>
            </Box>
          </Box>
        </Box>
        )
      }

  return (
    <BuilderProvider>  
      <TourProvider
    steps={[
      {
        selector: '.first-step',
        content: (props) => onboardingPopup({
          title: "Welcome to Silenium",
          description: "Welcome to Silenium! We're here to guide you through our platform's essential features. Let's get started on automating your sales outreach!",
          currentStepNumber: 1,
          ...props
        }),
        position: 'center',
        styles: {
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }),
        },
      },
      {
        selector: '[class*="tour-home-item"]',
        content: (props) => onboardingPopup({
          title: "Home Dashboard",
          description: "This is your Home dashboard. Here, you can view a snapshot of your organization's activity, including engagement metrics and open rates. Use this to monitor your overall performance.",
          currentStepNumber: 2,
          ...props
        }),
        padding: 0,
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        selector: '.tour-notifications-item',
        content: (props) => onboardingPopup({
          title: "Notifications",
          description: "Stay updated with real-time alerts and updates on campaign activities and lead progress in the Notifications section.",
          currentStepNumber: 3,
          ...props
        }),
        padding: 0,
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
        selector: '.tour-bot-item',
        content: (props) => onboardingPopup({
          title: "Bots",
          description: "Here in Bots, you can view and manage your Silenium bots. These bots handle the entire email outreach process, from gathering contacts for lead generation to follow-ups. Customize their behavior to suit your sales strategy.",
          currentStepNumber: 4,
          onNext: (props) => {
            const campaignsElement = document.querySelector('.tour-campaigns-item');
            if (campaignsElement) {
              campaignsElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
            setTimeout(() => {
              props.setCurrentStep((s) => s + 1);
            }, 500);
          },
          ...props,
        }),
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
        selector: '.tour-campaigns-item',
        content: (props) => onboardingPopup({
          title: "Campaigns",
          description: "The Campaigns page is your go-to place for setting up and managing email outreach campaigns. Define your ideal customer profile, configure email cadences, and create templates here.",
          currentStepNumber: 5,
          ...props
        }),
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
        selector: '.tour-help-center-item',
        content: (props) => onboardingPopup({
          title: "Help Center",
          description: "Need assistance? Visit the Help Center for tutorials, FAQs, and customer support. We're here to help you succeed.",
          currentStepNumber: 6,
          ...props
        }),
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
        selector: '.tour-settings-item',
        content: (props) => onboardingPopup({
          title: "Settings",
          description: "In Settings, you can manage your profile, notifications, and account preferences. Keep your account up-to-date and aligned with your needs.",
          currentStepNumber: 7,
          ...props
        }),
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
        selector: '.tour-logout-item',
        content: (props) => onboardingPopup({
          title: "Logout",
          description: "When you're done, click here to securely log out of your Silenium account.",
          currentStepNumber: 8,
          ...props
        }),
        position: 'right',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            top: "50px",
            left: "234px",
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "16px 16px 16px 0px",
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
        selector: '.tour-search-item',
        content: (props) => onboardingPopup({
          title: "Search",
          description: "Use the Search bar to quickly locate specific campaigns, bots, or contacts. A faster way to navigate your Silenium workspace!.",
          currentStepNumber: 9,
          onNext: (props) => {
            getStorageData("isCampaignList").then((isCampaignList) => {
              if (isCampaignList === "false") {
                const getStartedElement = document.querySelector('.tour-get-started-item');
                if (getStartedElement) {
                  getStartedElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                }
                setTimeout(() => {
                  props.setCurrentStep((s) => s + 1);
                }, 500);
              } else {
                navigate("/Campaigns");
                setTimeout(() => {
                  props.setCurrentStep((s) => s + 1);
                }, 1000);
              }
            });
          },
          ...props
        }),
        position: 'bottom',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            top: "50px",
            left: "252px",
            backgroundColor: 'white',
            borderRadius: "16px 0px 16px 16px", // Updated border radius
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        padding: 0,
      selector: '.tour-get-started-item',
        content: (props) => onboardingPopup({
          title: "New Campaign",
          description: "Click here to launch a new campaign. Define your target audience, email schedule, and templates in just a few steps. Your sales automation starts here!",
          currentStepNumber: 10,
          onPrevious: (props) => {
            getStorageData("isCampaignList").then((isCampaignList) => {
              if (isCampaignList === "true") {
                navigate("/Home");
                setTimeout(() => {
                  props.setCurrentStep((s) => s - 1);
                }, 1000);
              } else {

                props.setCurrentStep((s) => s - 1);
              }
            });
          },
          ...props
        }),
        position: 'bottom',
        styles: {
          maskArea: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          svgWrapper: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
          }),
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: "0px 16px 16px 16px", // Updated border radius
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          })
        }
      },
      {
        selector: '.finish-step',
        content: (props) => onboardingPopup({
          currentStepNumber: 11,
          title: "Finish Introduction",
          description: "Congratulations! You have successfully completed your Silenium introduction.",
          ...props
        }),
        position: 'center',
        styles: {
          popover: (base) => ({
            ...base,
            width: '400px',
            padding: 0,
            backgroundColor: 'white',
            borderRadius: '12px',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }),
        },
      },
    ]}
    showNavigation={false}
    showBadge={false}
    showCloseButton={false}
    >  
      <View style={{ minHeight: '100vh', width: '100vw' }}>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
      </TourProvider>
    </BuilderProvider>
  );
}

export default App;