import React from "react";

// Customizable Area Start
import { Box, Typography, TextField, Checkbox, Button, styled, Snackbar, Alert, IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { AppLogo, checkCircle, FormLogo, helpIcon, unCheckCircle } from "./assets";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handlePasswordViewIcon = () => {
    return <>{this.handleErrorPassword()}{!this.state.togglePasswordView ? <VisibilityOffOutlinedIcon data-testid="handleTogglePassword" style={styles.passwordIcon} onClick={this.handleTogglePassword} /> : <VisibilityOutlinedIcon style={styles.passwordIcon} onClick={this.handleTogglePassword} data-testid="handleTogglePassword" />
    }</>
  }

  handleErrorEmail = () => {
    if (this.state.emailErrorMsg !== '') {
      return <>
        <CloseRoundedIcon style={{ color: "red" }} />
      </>
    }
  }

  handleErrorPassword = () => {
    if (this.state.passwordErrorMsg !== '') {
      return <>
        <CloseRoundedIcon style={{ color: "red" }} />
      </>
    }
  }


  handleRenderModal = () => {
    return (
      <FormBox style={styles.formBox} >
        <Box style={styles.formInnerBox}>
          <Box style={styles.logoBox} >
            <Box style={styles.helpIcon}  >

              <img src={helpIcon.default} />
            </Box>
            <Box style={styles.AppLogo}  >
              <img src={FormLogo.default} />
            </Box>
          </Box>

          <Typography style={styles.logoText}>Welcome to Silenium</Typography>
          <Typography style={styles.loginText}>Where sales thrive, and customers shine.</Typography>
          <Typography style={styles.labelStyle} marginTop='15px' marginBottom='15px'>Login to your account</Typography>

          <Box display="flex" justifyContent='center' width='30rem' marginBottom='20px'>
            <Box style={styles.boxStyle} width="100%">
              <Typography style={styles.labelStyle}>Email</Typography>
              <TextField
                data-testid="handleEmailChange"
                type="email"
                placeholder="Email"
                fullWidth
                value={this.state.email}
                style={styles.textInput}
                onChange={this.handleEmailChange}
                InputProps={{ endAdornment: this.handleErrorEmail() }}
              />
              <Typography style={styles.errorText}>{this.state.emailErrorMsg}</Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent='center' width='30rem' marginBottom='20px'>
            <Box style={styles.boxStyle} width="100%">
              <Typography style={styles.labelStyle}>Password</Typography>
              <TextField
                data-testid="handleChangePassword"
                type={this.state.togglePasswordView ? "text" : "password"}
                placeholder="Enter your password"
                fullWidth
                style={styles.textInput}
                value={this.state.password}
                onChange={this.handleChangePassword}
                InputProps={{ endAdornment: this.handlePasswordViewIcon() }}
                inputProps={{ maxLength: 64 }}
              />
              <Typography style={styles.errorText}>{this.state.passwordErrorMsg}</Typography>

            </Box>
          </Box>

          <Box display={"flex"} alignItems={"center"} justifyContent='space-between' width='30rem'>
            <Box display='flex' justifyContent='center' alignItems='center' ml={"-.5rem"}>
              <Box>
                <Checkbox TouchRippleProps={{ style: { margin: "0px", padding: "0px" } }} icon={<span style={{ width: "22px", height: "22px", marginTop: "-.2rem" }} >{unCheckCircle}</span>}
                  checkedIcon={<span style={{ marginTop: "-.2rem", height: "22px", width: "22px" }}>{checkCircle}</span>} />
              </Box>
              <Box>
                <Typography>Remember me</Typography>
              </Box>

            </Box>
            <Box>
              <Typography data-testid="handleGoToForgotPassword" onClick={this.handleGoToForgotPassword} style={styles.forgotPass}>Forgot password?</Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent='center' alignItems="center" gap="16px" marginBottom="20px">
            <Button
              data-testid="loginAccount"
              onClick={this.handleLogin}
              style={{
                ...styles.acceptBtn,
                ...(this.state.activeBtn ? styles.acceptBtnCompleted : {}),
              }}
              disabled={!this.state.activeBtn}
            >
              Log in
            </Button>

            <Typography style={styles.consentText}>Are you new in Silenium? <b onClick={this.handleGoToSignUp} style={{ cursor: "pointer" }}>Create an account</b></Typography>
          </Box>
        </Box>
      </FormBox>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <ContainerBox>
          <FormBox>
            <img src={AppLogo.default} style={{ width: "436px", height: "102px" }} />
          </FormBox>
          {this.handleRenderModal()}

          <Snackbar
            open={this.state.isToastOpen}
            autoHideDuration={5000}
            onClose={this.handleCloseToast}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={this.handleCloseToast}
              icon={false}
              style={{
                backgroundColor: "#E6FAF0",
                color: "#000000",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                borderLeft: "4px solid #4CAF50",
              }}
              action={
                <IconButton size="small" aria-label="close" onClick={this.handleCloseToast}>
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              }
            >
              <div contentEditable='true' dangerouslySetInnerHTML={{ __html: "Your account has been verified" }}></div>
            </Alert>
          </Snackbar>
        </ContainerBox>
      </>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const ContainerBox = styled(Box)({
  display: "flex",
  flex: 1,
  width: "100%",
  height: "100%",
  backgroundColor: "#00184C",
  alignItems: "center",

  "@media (max-width: 1280px)": {
    flexDirection: "column",
    gap: "30px",
    paddingTop: "30px"
  },
})

// fix bugs related to UI of other experts.
const FormBox = styled(Box)({
  display: "flex",
  flex: 1,
  justifyContent: "center",
  alignItems: "center", 
  
  "@media (max-height: 800px)": {
    scale: 0.95
  },
  "@media (max-height: 750px), (max-width: 750px)": {
    scale: 0.9
  },
  "@media (max-height: 700px), (max-width: 700px)": {
    scale: 0.85
  },
  "@media (max-height: 650px), (max-width: 650px)": {
    scale: 0.75
  },
});

const styles = {

  formBox: {
    width: '50%'
  },
  formInnerBox: {
    width: "586px",
    height: "722px",
    backgroundColor: "rgba(235, 235, 235, 0.898)",
    border: "3px solid white",
    borderRadius: "16px",
    display: 'flex',
    flexDirection: 'column' as "column",
    justifyContent: 'start',
    alignItems: 'center',
  },
  logoBox: {
    alignItems: "center",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column" as 'column',
  },
  helpIcon: {
    display: "flex",
    justifyContent: 'end',
    alignItems: "center",
    marginTop: '15px',
    marginRight: '30px',
    marginBottom: '20px',
    width: "586px"
  },
  AppLogo: {
    display: "flex",
    justifyContent: "center",

    width: "100%",
  },
  logoText: {
    fontWeight: 700,
    fontSize: '30px',
    paddingTop: '35px',
    paddingBottom: '4px',
    height: "40px",
    color: "#334155",
    textAlign: 'center' as 'center',
  },
  loginText: {
    color: '#334155',
    textAlign: 'center' as 'center',
    fontSize: '16px'
  },
  labelStyle: {
    color: '#334155',
    fontSize: '14px',
    fontWeight: 700 as 700,
    paddingBottom: '4px',
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column" as "column"
  },
  textInput: {
    border: "1px solid #334155",
    backgroundColor: "white",
    borderRadius: "8px"
  },
  consentCheckbox: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    color: "#334155"
  },
  createButton: {
    marginTop: '40px',
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize",
    backgroundColor: '#00184C',
    color: 'white',
    marginBottom: '10px'
  },
  activeCreateButton: {
    color: 'white',
    backgroundColor: '#00184C'
  },
  acceptBtn: {
    marginTop: '40px',
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    color: '#00184C',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize"
  },
  acceptBtnCompleted: {
    marginTop: '40px',
    width: '207',
    height: '44px',
    border: '2px solid #00184C',
    borderRadius: '24px',
    backgroundColor: '#00184C',
    color: 'white',
    textContent: 'center',
    fontWeight: 700 as 700,
    textTransform: "capitalize" as "capitalize"
  },
  consentText: {
    color: "#334155",
    fontSize: "14px",
    paddingLeft: "6px",
    paddingTop: '3rem'
  },
  consentPara: {
    overflowY: 'scroll' as "scroll",
    backgroundColor: "#FFFFFF",
    padding: '10px',
    borderRadius: '12px',
    width: '474px',
    height: '400px',
  },
  forgotPass: {
    color: "#334155",
    fontSize: "14px",
    paddingLeft: "6px",
    cursor: "pointer",
    fontWeight: 700,
  },
  passwordIcon: {
    color: '#334155', cursor: "pointer"
  },
  errorText: {
    color: "#DC2626",
    fontWeight: 400,
    width: '30rem',
    textAlign: 'end' as 'end',
    marginTop: '4px'
  }
}

// Customizable Area End