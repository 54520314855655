import { useEffect, useRef } from "react";
import { NavigateFunction } from "react-router-dom";
import { removeStorageData, setStorageData } from "framework/src/Utilities";


const AUTO_LOGOUT_TIME = 15 * 60 * 1000;

interface InactivityHandlerProps {
  navigate: NavigateFunction;
}

const InactivityHandler: React.FC<InactivityHandlerProps> = ({ navigate }) => {
  const timeoutRef = useRef<number | null>(null);
  const logoutFn = () => {
    removeStorageData("token");
    setStorageData("token", undefined);
    navigate("EmailAccountLoginBlock");
  }

  const resetTimer = () => {
    if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => { 
      logoutFn();
      navigate("/login");
    }, AUTO_LOGOUT_TIME);
  };

  useEffect(() => {
    const events: string[] = ["mousemove", "keydown", "click"];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return null;
};

export default InactivityHandler;
