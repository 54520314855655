import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React, { RefObject } from "react";
import { Message } from "../../../framework/src/Message";
import 'react-toastify/dist/ReactToastify.css';
import { blankCircle, greenCheckCircle } from "./assets";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  email: string;
  isFormValid: boolean;
  activeBtn: boolean;
  token: string | null;
  activeNewPasswordBtn: boolean;
  password: string;
  confirmPassword: string;
  passwordValidate: boolean;
  passwordLowerCaseValidate: boolean;
  passwordCapitalCaseValidate: boolean;
  passwordOneNumberValidate: boolean;
  passwordEightDigitValidate: boolean;
  togglePasswordView: boolean;
  toggleConfirmPasswordView: boolean;
  newPasswordError: string;
  confirmationPage: boolean;

}

export interface SS {
  id: any;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {

  apiSentOtp: string = '';
  newPasswordApi: string = ''
  apiPrivatePolicy: string = '';
  otpToken: string = '';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      email: '',
      isFormValid: false,
      activeBtn: false,
      token: null,
      activeNewPasswordBtn: false,
      password: '',
      confirmPassword: '',
      passwordValidate: false,
      passwordLowerCaseValidate: false,
      passwordCapitalCaseValidate: false,
      passwordOneNumberValidate: false,
      passwordEightDigitValidate: false,
      togglePasswordView: false,
      toggleConfirmPasswordView: false,
      newPasswordError: '',
      confirmationPage: false,
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleOtpRequest(apiRequestCallId, responseJson)
      this.handleNewPasswordRequest(apiRequestCallId, responseJson)

      if (responseJson && responseJson.data !== undefined) {
        if (this.apiSentOtp === apiRequestCallId) {
          if (responseJson.meta && responseJson.meta.token) {
            this.otpToken = responseJson.meta.token;

            this.setState({ token: this.otpToken });

            //navigate to OTP page
            const msg: Message = new Message(
              getName(MessageEnum.NavigationMobilePhoneOTPMessage)
            );

            msg.addData(
              getName(MessageEnum.AuthTokenDataMessage),
              this.state.token
            );

            msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

            msg.addData(
              getName(MessageEnum.AuthTokenEmailMessage),
              this.state.email,
            );

            msg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);

            this.send(msg);
          }
        }
      } else {
        // just toast to show error, no need to test
        toast.error(responseJson?.errors?.[0]?.otp);
      }

    }
  }

  handleOtpRequest = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiSentOtp) {
      if (responseJson) {
        localStorage.setItem("otpToken", responseJson?.meta?.token)
        this.setState({ password: "", confirmPassword: "", activeNewPasswordBtn: false })
      }
    }
  }

  handleNewPasswordRequest = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.newPasswordApi) {
      if (!responseJson?.errors) {
        this.setState({ password: "", confirmPassword: "", activeNewPasswordBtn: false, confirmationPage: true })
      } else {
        if(responseJson?.errors[0]?.password=='The new password cannot be the same as the current password. Please choose a different password.'){
          toast.error(
            responseJson.errors[0].password,
            { position: toast.POSITION.TOP_LEFT }
          )
        }
        else{
          this.setState({ newPasswordError: "Oops! The password didn’t match. Please try again." })

        }
      }
    }
  }
  closeModal = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleGoToLogin = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), { id: this.props.id, navigation: this.props.navigation });
    this.send(message);
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;

    this.setState({ email });
    if (configJSON.emailRegex.test(email)) {
      this.setState({ activeBtn: true });
    } else {
      this.setState({ activeBtn: false });
    }
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    this.setState({ password: newPassword });
    this.validatePasswordRegex(newPassword);
    if (this.state.confirmPassword !== '' && newPassword !== '') {
      this.setState({ activeNewPasswordBtn: true })
    } else {
      this.setState({ activeNewPasswordBtn: false })
    }
  }

  handleConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newConfirmPassword = event.target.value;
    this.setState({ confirmPassword: newConfirmPassword, newPasswordError: "" })
    if (this.state.password !== '' && newConfirmPassword !== '') {
      this.setState({ activeNewPasswordBtn: true })
    } else {
      this.setState({ activeNewPasswordBtn: false })
    }
  }


  handleTogglePassword = () => {
    this.setState((prevState) => ({
      togglePasswordView: !prevState.togglePasswordView,
    }));
  };

  handleToggleConfirmPassword = () => {
    this.setState((prevState) => ({
      toggleConfirmPasswordView: !prevState.toggleConfirmPasswordView,
    }));
  };


  handleValidityPasswordIcon = (isCheck: boolean) => {
    if (isCheck) {
      return <img src={greenCheckCircle.default} />
    } else {
      return <img src={blankCircle.default} />
    }
  }

  handleSetNewPassword = () => {
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ newPasswordError: 'Oops! The password didn’t match. Please try again.' })
    } else {
      this.setState({ newPasswordError: '' })

      const header = {
        "Content-Type": "application/json"
      };
      const otpToken = localStorage.getItem("otpToken")

      const httpBody = {
        "data": {
          "token": otpToken,
          "password": this.state.password,
          "confirm_password": this.state.confirmPassword,
        }
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.newPasswordApi = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.newPasswordEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  }


  validatePasswordLowerCase(password: string) {
    let hasLowercase = false;
    for (const iterator of password) {
      if (iterator >= 'a' && iterator <= 'z') {
        hasLowercase = true;
      }

      if (hasLowercase) {
        return true;
      }
    }
    return false;
  }

  validatePasswordUpperCase(password: string) {
    let hasUppercase = false;

    for (const iterator of password) {
      if (iterator >= 'A' && iterator <= 'Z') {
        hasUppercase = true;
      }

      if (hasUppercase) {
        return true;
      }
    }
    return false;
  }

  validatePasswordRegex = (passwordTest: string) => {
    let flag = false;
    if (this.validatePasswordLowerCase(passwordTest)) {
      this.setState({ passwordLowerCaseValidate: true, })
    } else {
      this.setState({ passwordLowerCaseValidate: false, })
      flag = true
    }

    if (this.validatePasswordUpperCase(passwordTest)) {
      this.setState({ passwordCapitalCaseValidate: true, })
    } else {
      this.setState({ passwordCapitalCaseValidate: false })
      flag = true
    }

    if (configJSON.oneNumberValidate.test(passwordTest)) {
      this.setState({ passwordOneNumberValidate: true, })
    } else {
      this.setState({ passwordOneNumberValidate: false, })
      flag = true
    }

    if (passwordTest.length >= 8) {
      this.setState({ passwordEightDigitValidate: true })
    } else {
      this.setState({ passwordEightDigitValidate: false, })
      flag = true;
    }
    return flag;
  }

  handleSentOtp = () => {
    const header = {
      "Content-Type": "application/json"
    };

    const httpBody = {
      "data": {
        "attributes": {
          "email": this.state.email,
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSentOtp = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sentOtpEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

}
